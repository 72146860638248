import * as React from 'react';
import { Helmet } from 'react-helmet'
import { makeStyles } from '@material-ui/core/styles'
import { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';

import {
  ImageList,
  ImageListItem,
  ImageListItemBar
} from '../MUI'

import 
    bl1
    from '../pics/bl1.png'

import 
    bl2
    from '../pics/bl2.png'   

import 
    bl3
    from '../pics/bl3.png'

import 
    bl4
    from '../pics/bl4.png'

import 
bl5
from '../pics/bl5.png'

import 
bl6
from '../pics/bl6.png'

import 
bl7
from '../pics/bl7.png'

import 
bl8
from '../pics/bl8.png'

import 
bl9
from '../pics/bl9.png'

import 
bl10
from '../pics/bl10.png'

import 
bl11
from '../pics/bl11.png'

import 
bl12
from '../pics/bl12.png'

import 
bl13
from '../pics/bl13.png'

import 
bl14
from '../pics/bl14.png'

import 
bl15
from '../pics/bl15.png'

import 
bl16
from '../pics/bl16.png'

import 
bl17
from '../pics/bl17.png'

import 
bl18
from '../pics/bl18.png'

import 
bl19
from '../pics/bl19.png'

import 
bl20
from '../pics/bl20.png'

import 
bl21
from '../pics/bl21.png'

import
wasswa
  from '../pics/Wasswa 2.jpg'

import
guitar
  from '../pics/IMG_9937.jpg'

import
woman2
  from '../pics/DSC05336.JPG'

import
people
  from '../pics/_DSC0744 (5).jpg'

import
houses
  from '../pics/_DSC0696 (1).jpg'

import
threepeople
  from '../pics/IMG-20210203-WA0000.jpg'

import
boat
  from '../pics/facebook_1502290925364.jpg'

import
cows
  from '../pics/_DSC0939 (1).jpg'

import
truck
  from '../pics/4S6A0902.JPG'

import
children
  from '../pics/children sitting.JPG'

import
house
  from '../pics/IMG_9938.jpg'

import
landscape
  from '../pics/landscape.JPG'

import
schoolchildren
  from '../pics/Lira-Bara_ Uganda--08.030183.JPG'

import
man
  from '../pics/Lira-Bara_ Uganda--08.030239.JPG'

  import peopleSitting
    from '../pics/Screen Shot 2023-05-20 at 1.31.55 PM.png'

import
smilingman
  from '../pics/Uganda4-Introduction0060.JPG'

import
woman
  from '../pics/Wasswa 3.jpg'

  import peopleinRiver
    from '../pics/Lira-Bara_ Uganda--08.030223.JPG'

const useStyles = makeStyles((theme) => ({

  body: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  galleryContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '3vw'
    },
    padding: '10vmin',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    display: 'flex',
    flexWrap: 'wrap',
  },
  pic: {
    [theme.breakpoints.down('sm')]: {
      height: '40vmin'
    },
    height: '30vmin',
    "&:hover": {
      scale: '1.2',
      cursor: 'pointer'
    }
  },
  pictureViewer: {
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      height: '100%'
    },
    width: 'max-content',
    display: 'flex',
    marginTop: '2vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh'
  },
  openedPic: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1vh',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '2vh',
      width: '90%',
      height: 'auto'
    },
    height: '90%',
    marginTop: '2vh'
    
  },
  photoCredit: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '3vmin'
    },
    [theme.breakpoints.down('md')]: {
     marginLeft: '10%',
    },
    fontFamily: 'oswald',
    width: '100%',
    marginTop: '2vmin',
    color: 'white',
    fontSize: '2vmin'
  },
  close: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10vh',
    },
    "&:hover": {
      cursor: 'pointer'
    },
    color: 'white',
    marginTop: '2vmin'
    
  },
}))

const Booklaunchhighlights= ({ crossroads, setCrossroads }) => {

  useEffect(() => {
    setCrossroads(false)
  }, [])

  const [pic, setPic] = useState(0)
  const [openModal, setOpenModal] = useState(false)

  const handleOpenPic = (index) => {
    setPic(index)
    setOpenModal(true)
  }

  const classes = useStyles()

  const itemData = [
    {
      img: bl1,
      title: 'highlightpic',
      author: ' Ibua Publishing'
    },
    {
        img: bl2,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl3,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl4,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl5,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl6,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl7,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl8,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl9,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl10,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl11,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl12,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl13,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl14,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl15,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl16,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl17,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl18,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl19,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl20,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
      {
        img: bl21,
        title: 'highlightpic',
        author: ' Ibua Publishing'
      },
  ];

  return (<>
    <div className={classes.body}>
    <Helmet>
        <title>PHOTO GALLERY</title>
        <meta
                    name="description"
                    content="View the Photo Gallery for Remembering The Future"
                />
                <meta
                    name="keywords"
                    content="Uganda, Africa, Uganda Pictures, Africa Pictures, Ugandan Culture"
                />
    </Helmet>

      {
        openModal ?


          <div className={classes.pictureViewer}>
            <img src={itemData[pic].img} className={classes.openedPic}></img>
            <div className={classes.photoCredit}><span style={{color: 'black'}}>PHOTO CREDIT:</span><span style={{color: '#5a2a20'}}>{itemData[pic].author}</span></div>
            <div className={classes.close} onClick={() => setOpenModal(false)}><CloseIcon /></div>
          </div>


          :

          <div className={classes.galleryContainer}>
            {
              itemData.map((pic, index) => {
                return (
                  <div className="picDiv" onClick={() => handleOpenPic(index)}>
                    <img src={pic.img} className={classes.pic}></img>
                  </div>)
              })
            }
          </div>
      }
    </div>

  </>
  );
}


export default Booklaunchhighlights;