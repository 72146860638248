import * as React from 'react';
import { Helmet } from 'react-helmet'
import {makeStyles} from '@material-ui/core/styles'
import {useState, useEffect} from 'react'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { Link as Link1 } from 'react-router-dom'


const Chrisconte = ({crossroads, setCrossroads}) => {



    const useStyles = makeStyles((theme) =>({
        body: {
            height: '100%',
            minHeight: '100vh',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justfiyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
        },
        goBack: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: '7vmin',
                marginTop: '5vh',
                },
                maxWidth: '100vw',
                marginTop: '2%',
                marginLeft: '3%',
                display: 'flex',
                alignItems: 'center',
                color: '#5a2a20',
                fontFamily: 'oswald',
        },
        goBackText: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '5vmin',
                },
                fontSize: '1.8em'
        },
        
        backIcon: {
            [theme.breakpoints.down('sm')]: {
                transform: 'scale(2)',
                marginRight: '5vw'
                },
            "&:hover": {
                color: 'white',
                transform: 'scale(2.4)',
                cursor: 'pointer'
            },
            marginRight: '3vmin',
            transform: 'scale(2.4)'
        },
        videoWrap: {
            [theme.breakpoints.down('md')]: {
                width: '90vw'
            },
            width: '50%',
            height: '100%',
            marginTop: '5vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#5a2a20',
            boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)',
            borderRadius: '10px',
            padding: '1vmin'
        },
        titleContainer: {
            [theme.breakpoints.down('md')]:{
                fontSize: '5vw',
                paddingLeft: '2vmin',
                WebkitTextStroke: '0px',
                textShadow:'black 0px 0 0px'
                },
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems:'center',
                fontFamily: 'oswald',
                color: '#b68f72',
                fontSize: '2.5vw',
                marginTop: '2vh',
                marginBottom: '2vh',
           },
        descriptionContainer: {
            display: 'flex',
            width: '100vw',
            justifyContent: 'center'
        },
        description: {
            [theme.breakpoints.down('md')]: {
                fontSize: '4vw'
                },
            display: 'flex',
            justifyContent: 'center',
            width: '90vw',
            paddingTop: '2vh',
            paddingBottom: '2vh',
            fontSize:'1.5vw',
            fontStyle: 'italic',
            color: 'white'
        },
        videoContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '100vw',
            marginBottom: '2vh',
        },
        responsiveiFrame: {
            [theme.breakpoints.down('md')]: {
            width: '90vw',
            height: '54vw'
            },
         width: '40vw',
         height: '24vw'
        }
    }))

    useEffect(() => {
        setCrossroads(true)
    }, [])

    const classes = useStyles()

    return <>
    
        <div className={classes.goBack}>
            <Link1 to="/crossroadshome" style={{ color: 'inherit', textDecoration: 'none' }}>
                    <ArrowCircleLeftOutlinedIcon className={classes.backIcon}/>
            </Link1> <div className={classes.goBackText}>BACK TO CROSSROADS HOME</div>
        </div>
        <div className={classes.body}>
                <Helmet>
                    <title>CHRIS CONTE</title>
                    <meta
                    name="description"
                    content="Chris Conte on the Idea of Crossroads"
                />
                </Helmet>            
                <div className={classes.videoWrap}>
                <div className={classes.title}>
                        
                <div className={classes.titleContainer}>
                    CHRIS CONTE ON THE IDEA OF CROSSROADS
                </div>
                </div>
                <div className={classes.videoContainer}>
                    <iframe className={classes.responsiveiFrame} src="https://www.youtube.com/embed/NrBulyqXiI0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
    </div>
    </>
    
}

export default Chrisconte