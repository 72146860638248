import * as React from 'react';
import { Helmet } from 'react-helmet'
import { makeStyles } from '@material-ui/core/styles'
import { useState, useEffect, Link } from 'react'
import CloseIcon from '@mui/icons-material/Close';

import {
  ImageList,
  ImageListItem,
  ImageListItemBar
} from '../MUI'

import
wasswa
  from '../pics/Wasswa 2.jpg'

import
guitar
  from '../pics/IMG_9937.jpg'

import
woman2
  from '../pics/DSC05336.JPG'

import
people
  from '../pics/_DSC0744 (5).jpg'

import
houses
  from '../pics/_DSC0696 (1).jpg'

import
threepeople
  from '../pics/IMG-20210203-WA0000.jpg'

import
boat
  from '../pics/facebook_1502290925364.jpg'

import
cows
  from '../pics/_DSC0939 (1).jpg'

import
truck
  from '../pics/4S6A0902.JPG'

import
children
  from '../pics/children sitting.JPG'

import
house
  from '../pics/IMG_9938.jpg'

import
landscape
  from '../pics/landscape.JPG'

import
schoolchildren
  from '../pics/Lira-Bara_ Uganda--08.030183.JPG'

import
man
  from '../pics/Lira-Bara_ Uganda--08.030239.JPG'

  import peopleSitting
    from '../pics/Screen Shot 2023-05-20 at 1.31.55 PM.png'

import
smilingman
  from '../pics/Uganda4-Introduction0060.JPG'

import
woman
  from '../pics/Wasswa 3.jpg'

  import peopleinRiver
    from '../pics/Lira-Bara_ Uganda--08.030223.JPG'

// import one
//   from '../pics/1.png'

//   import two
//   from '../pics/2.png'

//   import three
//   from '../pics/3.png'

//   import four
//   from '../pics/4.png'

//   import five
//   from '../pics/5.png'

//   import six
//   from '../pics/6.png'

//   import seven
//   from '../pics/7.png'

//   import eight
//   from '../pics/8.png'

//   import nine
//   from '../pics/9.png'

//   import ten
//   from '../pics/10.png'

//   import eleven
//   from '../pics/11.png'

//   import twelve
//   from '../pics/12.png'

//   import thirteen
//   from '../pics/13.png'

//   import fourteen
//   from '../pics/14.png'

//   import fifteen
//   from '../pics/15.png'

const useStyles = makeStyles((theme) => ({

  body: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  galleryContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '3vw'
    },
    padding: '10vmin',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    display: 'flex',
    flexWrap: 'wrap',
  },
  pic: {
    [theme.breakpoints.down('sm')]: {
      height: '40vmin'
    },
    height: '30vmin',
    "&:hover": {
      scale: '1.2',
      cursor: 'pointer'
    }
  },
  pictureViewer: {
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      height: '100%'
    },
    width: 'max-content',
    display: 'flex',
    marginTop: '2vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh'
  },
  openedPic: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1vh',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '2vh',
      width: '90%',
      height: 'auto'
    },
    height: '90%',
    marginTop: '2vh'
    
  },
  photoCredit: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '3vmin'
    },
    [theme.breakpoints.down('md')]: {
     marginLeft: '10%',
    },
    fontFamily: 'oswald',
    width: '100%',
    marginTop: '2vmin',
    color: 'white',
    fontSize: '2vmin'
  },
  close: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10vh',
    },
    "&:hover": {
      cursor: 'pointer'
    },
    color: 'white',
    marginTop: '2vmin'
    
  },
}))

const Gallery = ({ crossroads, setCrossroads }) => {

  useEffect(() => {
    setCrossroads(false)
  }, [])

  const [pic, setPic] = useState(0)
  const [openModal, setOpenModal] = useState(false)

  const handleOpenPic = (index) => {
    setPic(index)
    setOpenModal(true)
  }

  const classes = useStyles()

  const itemData = [
    {
      img: wasswa,
      title: 'Wasswa',
      author: ' JAMES WASSWA'
    },
    {
      img: guitar,
      title: 'Woman with guitar',
      author: ' KENNEDY KIHIRAY',
    },
    {
      img: woman2,
      title: 'woman2',
      author: ' N/A'
    },
    {
      img: people,
      title: 'People',
      author: ' FLORENCE KYOHANGIRWE',
    },
    {
      img: houses,
      title: 'Village',
      author: ' FLORENCE KYOHANGIRWE',
      
    },
    {
      img: threepeople,
      title: 'Smiles',
      author: ' FLORENCE KYOHANGIRWE',
    },
    {
      img: boat,
      title: 'Boat',
      author: ' MERCEDES SAYAGUES',
    },
    {
      img: cows,
      title: 'Cattle',
      author: ' FLORENCE KYOHANGIRWE',
    },
    {
      img: truck,
      title: 'Truck',
      author: ' BADRELDIN KHALAF',
    },
    {
      img: children,
      title: 'Truck',
      author: ' PARISA AZADI',
    },
    {
      img: house,
      title: 'Truck',
      author: ' KENNEDY KIHIRAY',
    },
    {
      img: landscape,
      title: 'Truck',
      author: ' PARISA AZADI',
    },
    {
      img: schoolchildren,
      title: 'Truck',
      author: ' N/A',
    },
    {
      img: man,
      title: 'Woman Looking into Camera',
      author: 'N/A',
    },
    {
      img: peopleSitting,
      title: 'People',
      author: ' N/A'
    },
    {
      img: smilingman,
      title: 'Truck',
      author: ' N/A',
    },
    {
      img: woman,
      title: 'Woman Sitting',
      author: ' JAMES WASSWA',
    },
    {
      img: peopleinRiver,
      title: 'People in River',
      author: ' N/A'
    },
  ];

  return (<>
    <div className={classes.body}>
    <Helmet>
        <title>PHOTO GALLERY</title>
        <meta
                    name="description"
                    content="View the Photo Gallery for Remembering The Future"
                />
                <meta
                    name="keywords"
                    content="Uganda, Africa, Uganda Pictures, Africa Pictures, Ugandan Culture"
                />
    </Helmet>

      {
        openModal ?


          <div className={classes.pictureViewer}>
            <img src={itemData[pic].img} className={classes.openedPic}></img>
            <div className={classes.photoCredit}><span style={{color: 'black'}}>PHOTO CREDIT:</span><span style={{color: '#5a2a20'}}>{itemData[pic].author}</span></div>
            <div className={classes.close} onClick={() => setOpenModal(false)}><CloseIcon /></div>
          </div>


          :

          <div className={classes.galleryContainer}>
            {
              itemData.map((pic, index) => {
                return (
                  <div className="picDiv" onClick={() => handleOpenPic(index)}>
                    <img src={pic.img} className={classes.pic}></img>
                  </div>)
              })
            }
          </div>
      }
    </div>

  </>
  );
}


export default Gallery;