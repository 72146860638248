import * as React from 'react';
import { Helmet } from 'react-helmet'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ClassNames } from '@emotion/react';
import { Link } from 'react-router-dom'
import amazon from '../pics/Amazon_PNG6.png'
import ibua from '../pics/ibua.png'
import mahiri from '../pics/mahiribooks.png'

const useStyles = makeStyles((theme) => ({
    body: {
        marginTop: '2vmin',
        height: '100%',
        width: '100vw',
        minHeight: '100vh',
        minWidth: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    titleContainer: {
        [theme.breakpoints.down('md')]: {
            fontSize: '10vmin',
            paddingBottom: '7vmin',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        },
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'oswald',
        color: '#5a2a20',
        fontSize: '8vmin',
        paddingBottom: '4vmin',
        WebkitTextStroke: '2px',
        WebkitTextStrokeColor: '#b68f72',
        textShadow: 'black 2px 0 5px'
    },
    title: {
        color: '#b68f72',
        fontSize: '5vmin',
        borderBottom: '3px solid #b68f72',
        width: '100%',
        
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
            paddingBottom: '7vmin',
            width: '95vw',
        },

        backgroundColor: '#5a2a20',
        padding: '2vh',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)',
        borderRadius: '10px',
    },
    option1: {
        [theme.breakpoints.down('sm')]: {
            height: '100%'
        },
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        fontFamily: 'oswald',
        height: '15vh',
        marginBottom: '5%'
    },
    option2: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        fontFamily: 'oswald',
        height: '100%'
    },
    details: {
        display: 'flex',
        alignContent: 'center',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        paddingTop: '3vmin'
    },
    img: {
        [theme.breakpoints.down('sm')]: {
            width: '20vmin',
            marginTop: '2vh'
        },
        paddingTop: '2vh',
        width: '12vmin',
        backgroundColor: 'white',
        padding: '1vmin'

    },
    textIntro: {
        color:'#b68f72'
    },
    details2: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
        },
        display: 'flex',
        flexDirection: 'column',
        fontSize: '3vmin',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        paddingLeft: '2vw',
        paddingRight: '2vw'
    },
    list: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
            marginLeft: '2vw'
        },
        display: 'flex',
        flexDirection: 'column',
        fontSize: '2vmin'
    },
    li: {
        paddingBottom: '.5vh'
    }

}))

const Buy = ({ crossroads, setCrossroads }) => {

    useEffect(() => {
        setCrossroads(false)
    }, [])

    const classes = useStyles()

    return (
        <div className={classes.body}>
            <Helmet>
                <title>BUY</title>
                <meta
                    name="description"
                    content="Where to Buy Remembering The Future"
                />
            </Helmet>
            <div className={classes.titleContainer}>
                BUY
            </div>
            <div className={classes.container}>
                <div className={classes.option1}>
                    <div className={classes.title}>
                        ONLINE / WORLDWIDE
                    </div>
                    <div className={classes.details}>
                        <a target="_blank" href='https://www.amazon.com/dp/B0C7T1NQQ5/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1686772217&sr=8-1'>
                            <img className={classes.img} src={amazon}></img>
                        </a>
                    </div>
                </div>
                

                <div className={classes.option2}>
                    <div className={classes.title}>
                        IN AFRICA
                    </div>

                    <div className={classes.details2}>
                        <br></br>
                        
                        <a target="_blank" href='https://ibuapublishing.com/shop/'>
                            <img className={classes.img} src={ibua}></img>
                        </a>
                    </div>
                </div>
                <div className={classes.option2}>

                    <div className={classes.details2}>
                        <br></br>
                        
                        <a target="_blank" href='https://mahiribooks.com/products/remembering-the-future-reflections-on-ugandan-culture-in-changing-times-by-christopher-conte-with-hilda-twongyeirwe?_pos=1&_sid=af7e7132c&_ss=r'>
                            <img className={classes.img} src={mahiri}></img>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Buy;