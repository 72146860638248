import * as React from 'react';
import {Link} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'



import edna from '../pics/edna.png'
import joachim from '../pics/joachim.png'
import kaiza from '../pics/kaiza.png'
import joseph from '../pics/joseph.png'
import achelam from '../pics/achelam.png'
import carol from '../pics/carol.png'
import amooti from '../pics/amooti.png'
import aliker from '../pics/Aliker.png'
import regina from '../pics/regina.png'
import stephen from '../pics/stephen.png'
import linda from '../pics/linda.png'
import flavia from '../pics/flavia.png'
import chris from '../pics/chris.png'
import hilda from '../pics/Hilda.jpg'

const useStyles = makeStyles((theme) => ({
    body: {
        marginTop: '2vmin',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    titleContainer: {
        [theme.breakpoints.down('md')]: {
            fontSize: '10vmin',
            paddingBottom: '7vmin',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        },
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'oswald',
        color: '#5a2a20',
        WebkitTextStroke: '2px',
        WebkitTextStrokeColor: '#b68f72',
        fontSize: '8vmin',
        paddingBottom: '4vmin',
        textShadow: 'black 0px 0 5px'
    },
    authorContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '95vw',
            position: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            width: '95vw',
            position: 'center',
        },
        backgroundColor: '#5a2a20',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)',
        borderRadius: '10px',
        width: '75vw',
        height: '100%',
        
        padding: '2vh',
        marginBottom: '5vh'
    },
    authorBox: {
        [theme.breakpoints.down('lg')]: {
            marginBottom: '10vh',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '2vh'
        },
        marginTop: '2vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'sans-serif',
        marginBottom: '3vh',
    },
    reverseAuthorBox: {
        [theme.breakpoints.down('lg')]: {
            marginBottom: '10vh',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '2vh'
        },
        marginTop: '2vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'sans-serif',
        marginBottom: '3vh',
    },
    nameAndPic: {
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.down('md')]: {
        //    width: '55%'
        },
        [theme.breakpoints.down('lg')]: {
            width: '60%',
         },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    authorPicture: {
        height: 'auto',
        width: '12vw',
        borderRadius: '50%',
        paddingBottom: '1vh',
        [theme.breakpoints.down('sm')]: {
            margin: '2vmin',
            width: '25vh'
        },
        [theme.breakpoints.down('md')]: {
            width: '60%'
        },
    },
    authorName: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            fontSize:'2.5vmin'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            fontSize: '7vmin',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        },
        display: 'flex',
        justifyContent: 'center',
        
        color: '#b68f72',
        fontSize: '3.5vmin',
        fontFamily: 'oswald',
        fontWeight: 'bold',
        WebkitTextStroke: '1px',
        WebkitTextStrokeColor: 'black',
        textShadow: 'black 1px 0 5px'
    },
    amootiName: {
        [theme.breakpoints.down('md')]: {
            fontSize:'2.5vmin'
            
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            width: '90vw',
            fontSize: '5.5vmin',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        }, //NEW
        whiteSpace: 'no-wrap',
        
        color: '#b68f72',
        fontSize: '3vmin',
        fontFamily: 'oswald',
        fontWeight: 'bold',
        WebkitTextStroke: '1px',
        WebkitTextStrokeColor: 'black',
        textShadow: 'black 1px 0 5px'
    },
    authorBio: {
        [theme.breakpoints.down('lg')]: {
            paddingLeft: '0'
        },
        [theme.breakpoints.down('sm')]: {
            width: '93vw',
            fontSize: '5vmin',
            padding: '5vw'
        },
        width: '70%',
        color: 'white',
        paddingLeft: '5%',
        fontSize: '2vmin',
        
        fontFamily: "Open Sans, sans-serif",
        
    },
    reverseAuthorBio: {
        [theme.breakpoints.down('lg')]: {
            paddingRight: '0'
        },
        [theme.breakpoints.down('sm')]: {
            width: '93vw',
            fontSize: '5vmin',
            padding: '5vw'
        },
        width: '70%',
        color: 'white',
        paddingRight: '5%',
        fontSize: '2vmin',
        fontFamily: "Open Sans, sans-serif",
        
        
    },
    authorTitle: {
        width: '100%',
        position: 'center',
        alignItems: 'center',
    },
    link: {
        textDecoration: 'none',
        color: 'white',
        borderBottom: '1px solid white',
        borderBottomColor: 'blue'

    }
}))



const Authors = ({ crossroads, setCrossroads }) => {

    useEffect(() => {
        setCrossroads(false)
    }, [])

    const classes = useStyles();

    return (<>
        <div className={classes.body}>
            <Helmet>
                <title>AUTHORS</title>
                <meta
                    name="description"
                    content="Meet the authors of Remembering The Future"
                />
                <meta
                    name="keywords"
                    content="Authors, African Authors, Ugandan Authors"
                />
            </Helmet>
            <div className={classes.titleContainer}>
                <span>AUTHORS</span>
            </div>
            <div className={classes.authorContainer}>
                <div className={classes.authorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={linda}></img>
                        <div className={classes.authorName}>LINDA ORANDO</div>
                    </div>
                    <div className={classes.authorBio}>
                        Linda Akoth Orando was born and raised by Winifred Akello Orando and Mercellus Orando of
                        the Goria clan of the Japadola tribe in eastern Uganda. She
                        has lived most of her life in Kampala. She received a
                        bachelor’s degree in architecture from Makerere University
                        and has practiced architecture for 10 years as a project
                        manager, interior designer, furniture designer, and
                        contractor. She performs social work to empower a group of
                        the urban poor in Kampala through skills training and literacy
                        learning.
                        <br></br>
                        <br></br>
                        Linda describes Remembering the Future as a personal rite of passage in a soul-searching
                        interview on the podcast <Link className={classes.link} onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/afiaorando">“African Father in America.”</Link>
                    </div>
                </div>
                <div className={classes.reverseAuthorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={amooti}></img>
                        <div className={classes.amootiName}>WOBUSOBOZI AMOOTI KANGERE</div>
                    </div>
                    <div className={classes.reverseAuthorBio}>
                        Wobusobozi Amooti Kangere, a writer, publisher, and artistic director, runs the publishing
                        enterprise Ibua Publishing, which was established to promote
                        reading and writing in African societies with oral traditions. A
                        lover of literature, history, theatre, film, debate, expressive art
                        and learning, he defines his mission as documenting and
                        preserving African history, lore, culture and knowledge systems,
                        and seeing Africans assume their place as equal players at the
                        global cultural table. His poems appear in two anthologies
                        – “Broken Voices of the Revolution” and “Streetlights at Noon
                        Eclipse.” He contributes essays to Journal.ibuapublishing.com
                    </div>
                </div>
                <div className={classes.authorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={edna}></img>
                        <div className={classes.authorName}>EDNA NAMARA</div>
                    </div>
                    <div className={classes.authorBio}>
                        Edna Namara is a news reporter
                        for Global Press Journal, an international online publishing
                        House. Born in Kampala but raised in Rukiga district, she
                        attended Old Kampala secondary school and received a BA
                        degree in Education and a Master’s degree in Education
                        Management and Policy Planning from Kyambogo University. Her
                        undergraduate research was on The Force and Literary Merit of
                        Oral Literature among her people, the Bakiga. She has loved
                        storytelling since her early childhood.
                        <br></br>
                        <br></br>
                        You can hear Edna share stories on topics ranging from childhood to marriage to the end
                        of life in an interview on the podcast <Link className={classes.link} onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/afianamara">"African Father in America.”</Link>
                    </div>
                </div>
                <div className={classes.reverseAuthorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={joachim}></img>
                        <div className={classes.amootiName}>JOACHIM BUWEMBO</div>
                    </div>
                    <div className={classes.reverseAuthorBio}>
                        Joachim Buwembo is a distinguished Ugandan journalist and
                        social commentator. He has served as Sunday Editor for the New
                        Vision newspaper and as managing editor of the Daily Monitor
                        newspaper. In 2004, he became the founding editor of The Citizen
                        newspaper in Tanzania, which is owned by the Nation Media
                        Group, the largest media group in east and central Africa. He also
                        created Kilimo Kwanza – Agriculture First – an agriculture
                        supplement that focuses on poverty and development issues in
                        Tanzania. Buwembo won acclaim for his book, “How to be a
                        Ugandan” (2002), which deconstructs Ugandan society in a series
                        of profiles of social types. He followed that with “The Ugandan
                        Paradox” (2012), which describes Uganda as “a rich country of
                        poor people” in addressing varied topics.
                    </div>
                </div>
                <div className={classes.authorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={kaiza}></img>
                        <div className={classes.authorName}>A.K. KAIZA</div>
                    </div>
                    <div className={classes.authorBio}>
                        A.K. Kaiza is a Ugandan writer and journalist, who joined the
                        media in 1994. He has worked at the former Uganda Television
                        and at The East African newspaper as a reporter at large and
                        pioneering art and literary journalism and critic. He has worked
                        in Kenya as literary editor for the magazine Kwani and as editor
                        of the online arts magazine, AfricanColours. He is currently
                        involved in arts and cultural development.
                    </div>
                </div>
                <div className={classes.reverseAuthorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={carol}></img>
                        <div className={classes.authorName}>CAROLINE ARIBA</div>
                    </div>
                    <div className={classes.reverseAuthorBio}>
                        Caroline Ariba is a journalist, researcher, and media
                        consultant. A graduate of Makerere University and former
                        Global Health Scholar at the Queen Mary University of London,
                        her passion for culture and social justice has informed her
                        reporting, winning her multiple awards both locally and
                        internationally. She has worked with the New Vision media
                        group in Uganda, the Guardian and The Times in the UK, and
                        City Press in South Africa. She also contributed to several
                        media outlets across the globe. Caroline was part of the eleven
                        women authors of Crossroads: Women Coming of Age in
                        Today’s Uganda.
                        <br></br>
                        <br></br>
                        Caroline shares memories of childhood defined by freedom and play in the village of her
                        youth – and regrets that villages today are a pale shadow of what they once were on the <Link className={classes.link} onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/afiaariba">"African Father in America"</Link> podcast.
                    </div>
                </div>
                <div className={classes.authorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={joseph}></img>
                        <div className={classes.amootiName}>JOSEPH ELUNYA SR.</div>
                    </div>
                    <div className={classes.authorBio}>
                        Joseph Elunya Sr. was born into a peasant family in Oderai
                        village in eastern Uganda’s Soroti District. He spent his early
                        life helping his parents cultivate food crops and raise cattle
                        for the family’s subsistence. At the age of nine, he assumed
                        responsibility for raising money to pay his school fees by
                        selling vegetables. After high school, he joined Voice of Teso
                        radio station as a newscaster and later as News Editor. He
                        also has worked as Bureau Chief for the Institute of War and
                        Peace Reporting at Uganda Radio Network. He is co-director
                        of the Centre for Investigative Journalism in Uganda. He
                        holds a Diploma in Journalism, a Bachelor of Ethics and
                        Development Studies, and a Master of Science in Monitoring and Evaluation.
                    </div>
                </div>
                <div className={classes.reverseAuthorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={regina}></img>
                        <div className={classes.authorName}>REGINA ASINDE</div>
                    </div>
                    <div className={classes.reverseAuthorBio}>
                        Regina Asinde is an activist, facilitator, trainer, publisher,
                        editor and author who passionately believes in stories as a
                        powerful tool for social change. She is the Managing Director
                        of Wordsmith Publications Uganda Limited and the author
                        of Shards of Brokenness (a poetry collection) and Sullied
                        Threads (a short story collection).  Her other works have
                        been published in a number of anthologies and various
                        online magazines.  She lives in Tororo, Uganda, with her
                        husband and children.
                        <br></br>
                        <br></br>
                        Regina tells interviewer Simon Javan Okelo of the <Link className={classes.link} onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/afiaasinde">"African Father In America"</Link> podcast that young Africans today must listen to their
                        elders’ stories because “we are fast losing out on our stories, on our values on what makes us
                        African.”
                    </div>
                </div>
                <div className={classes.authorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={aliker}></img>
                        <div className={classes.authorName}>ALIKER P'OCITTI</div>
                    </div>
                    <div className={classes.authorBio}>
                        Aliker P’Ocitti is a poet, author of two books – My Mayor:
                        The Political Campaign Story of a Poor Elite and Rich
                        Illiterate and Even Female Dogs Hunt (publication pending)
                        – and a collection of poems in the book “Hidden Scars.” He
                        also is a blogger and online news editor, and he has served
                        as a local television talk show host. He has contributed
                        opinion to major Ugandan newspapers and online
                        publications and has published literary works in the African
                        Book Collective: Best “New” African Poets Anthology 2020
                        and other books. He holds a B.A. Education and has earned
                        Master’s Degrees from Makerere University and the
                        University of San Diego.
                        <br></br>
                        <br></br>
                        Aliker discusses the role his chapter in Remembering the Future played in his growth as a
                        writer on the <Link className={classes.link} onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/afiapocitti">"African Father in America"</Link> podcast.
                    </div>
                </div>
                <div className={classes.reverseAuthorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={achelam}></img>
                        <div className={classes.amootiName}>ACHELAM KINYERA</div>
                    </div>
                    <div className={classes.reverseAuthorBio}>
                        Achelam Kinyera was born in Northern Uganda and grew up
                        in Kampala. He completed secondary school at Uganda
                        Martyrs Secondary School, Namugongo, and then joined
                        Makerere University and trained as a medical doctor. A
                        member of the Lantern Meet of Poets, he has written poetry,
                        performed all over Kampala and played numerous roles in
                        theatre productions. His poetry was published in an
                        anthology, “Streetlights At Noon Eclipse,” and he has
                        published short stories, essays, and reviews online in the
                        Ibua Journal, for which he is a regular contributor and part of
                        the editorial team. He lives in Kampala and is eternally
                        haunted by manuscripts he hopes will one day see the light
                        of day.
                    </div>
                </div>
                <div className={classes.authorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={stephen}></img>
                        <div className={classes.amootiName}>STEPHEN SSENKAABA</div>
                    </div>
                    <div className={classes.authorBio}>
                        Stephen Ssenkaaba, an award-winning features writer and
                        editor, was a contributing editor for The New Vision, where
                        he specialized in covering arts and culture, education and
                        development issues. As a 2018-2019 Knight Wallace
                        Journalism Research Fellow at the University of Michigan, he
                        studied the role of online technologies in journalism in Africa.
                        He subsequently helped establish the New Vision’s first-ever
                        podcasting platform. He has taught undergraduate courses in
                        online and digital communication, editing, feature writing
                        and contemporary issues in media and society at Uganda
                        Christian University. As this book went to press, he was a PhD student in Communications and Media Studies at the University of Oregon.
                        <br></br>
                        <br></br>
                        Stephen, a working journalist and PhD candidate reflects on the role of digital media in
                        African society during an interview on the podcast <Link className={classes.link} onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/afiassenkaaba">“African Father in America.”</Link>
                    </div>
                </div>
                <div className={classes.reverseAuthorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={flavia}></img>
                        <div className={classes.authorName}>FLAVIA NASSAKA</div>
                    </div>
                    <div className={classes.reverseAuthorBio}>
                        Flavia Nassaka is a journalist based in Kampala. A graduate of
                        Makerere University, she currently works with Uganda Radio
                        Network, a news agency that offers hard news, documentaries, and
                        magazine stories to subscribing newspapers, radio stations, and
                        online media outlets. She is a mother of two boys, Jamal and Jibril.
                        Flavia developed a passionate interest in health science as a print
                        journalist for the Independent Magazine. She has won two awards
                        for excellence in health journalism. Her greatest fulfillment comes
                        from comprehending scientific issues that often tend to be
                        complicated to non-scientists.
                    </div>
                </div>
                <div className={classes.authorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={chris}></img>
                        <div className={classes.amootiName}>CHRISTOPHER CONTE</div>
                    </div>
                    <div className={classes.authorBio}>
                        Christopher Conte is a writer, editor and journalism teacher
                        based in Washington, DC. A former reporter and editor for the
                        Wall Street Journal, he has trained journalists on health,
                        economic, and social issues, and writing. He has worked with
                        the International Center for Journalists (which introduced him
                        to Uganda) and the World Association of Newspapers and
                        News Publishers. He also has consulted and edited for a
                        number of nonprofit organizations, including the World Bank,
                        the International Finance Corporation, and Gateway House –
                        the Indian Council on Global Relations.
                        <br></br>
                        <br></br>
                        Watch Chris explain his love of Uganda and how he became an editor for Ugandan writers
                        in the podcast <Link className={classes.link} onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}to="/afiaconte">“African Father in America.”</Link>
                    </div>
                </div>
                <div className={classes.reverseAuthorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={hilda}></img>
                        <div className={classes.amootiName}>HILDA TWONGYEIRWE</div>
                    </div>
                    <div className={classes.reverseAuthorBio}>
                    Hilda J. Twongyeirwe is a writer and
                    book editor from Western Uganda. She
                    works with FEMRITE – Uganda
                    Women Writers Association, and has
                    coordinated writing projects for women
                    in Uganda, South Sudan and the United
                    States. She is a member of the Lions Club
                    of Kampala Central and The Graca
                    Machel Trust – Women in Media
                    Network. She serves on the International Advisory Board of African
                    278
                    Research Universities Alliance – Makerere University. A Fellow of the
                    USA International Visitors Leadership Programme, she received the
                    Uganda Government National Medal and the Uganda Registration
                    Services Bureau Award for her contribution to Uganda's literary arts.
                    She is a mountain climber – a sport that has taken her to special spaces
                    such as Mount Moroto, which is mentioned in Remembering the
                    Future.
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default Authors