import * as React from 'react';
import { Helmet } from 'react-helmet'
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles'

import { Link as Link1 } from 'react-router-dom'

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

const useStyles = makeStyles((theme) => ({
    goBack: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '7vmin',
            marginTop: '5vh',
        },
        maxWidth: '100vw',
        marginTop: '2%',
        marginLeft: '3%',
        display: 'flex',
        alignItems: 'center',
        color: '#5a2a20',
        fontFamily: 'oswald',
    },
    goBackText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
        },
        fontSize: '1.8em'
    },

    backIcon: {
        [theme.breakpoints.down('sm')]: {
            transform: 'scale(2)',
            marginRight: '5vw'
        },
        "&:hover": {
            color: 'white',
            transform: 'scale(2.4)',
            cursor: 'pointer'
        },
        marginRight: '3vmin',
        transform: 'scale(2.4)'
    },
    titleContainer: {
        [theme.breakpoints.down('md')]: {
            fontSize: '10vmin',
            paddingBottom: '7vmin',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        },
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'oswald',
        color: '#5a2a20',
        fontSize: '8vmin',
        marginTop: '2vh',
        paddingBottom: '4vmin',
        WebkitTextStroke: '2px',
        WebkitTextStrokeColor: '#b68f72',
        textShadow: 'black 1px 0 5px'
    },
    cr: {
        width: '35vmin',
        marginLeft: '1vw'
    },
    title: {
        [theme.breakpoints.down('md')]: {
            fontSize: '3.5vmin',
            paddingBottom: '3vh'
        },

        fontSize: '3vmin',
        fontFamily: 'oswald',
        marginBottom: '3%',
        color: 'white',
    },
    body: {
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '5vh'
    },
    reviewContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '90vw'
        },
        width: '50vw',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#5a2a20',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)',
        borderRadius: '10px',
        padding: '2vmin'
    },
    reviewBox: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '4.5vmin'
        },
        marginBottom: '5vh',
        color: 'white',
        fontSize: '2vmin',
        fontFamily: 'Roboto Serif, serif',
        borderBottom: '3px solid white'
    },
    reviewContent: {
        marginBottom: '2vw'
    },
}))

const Crossroadreviews = ({ crossroads, setCrossroads }) => {

    useEffect(() => {
        setCrossroads(true)
    }, [])

    const classes = useStyles()

    return <>
        <div className={classes.goBack}>
            <Link1 to="/crossroadshome" style={{ color: 'inherit', textDecoration: 'none' }}>
                <ArrowCircleLeftOutlinedIcon className={classes.backIcon} />
            </Link1> <div className={classes.goBackText}>BACK TO CROSSROADS HOME</div>
        </div>
        <div className={classes.titleContainer}>REVIEWS
        </div>
        <div className={classes.body}>
            <Helmet>
                <title>REVIEWS</title>
                <meta
                    name="description"
                    content="Read reviews of Crossroads"
                />
            </Helmet>
            <div className={classes.reviewContainer}>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>“This was powerful!  The stories showed me a side of things I didn’t even know existed, and struggles I am have been sheltered enough to not really have known about.  The women telling their truths in this book put their hearts into it, and each and every one of them was moving, showing the lives of these incredible people.  Wonderful collection!” (<a style={{ color: '#B49B57', textDecoration: 'none', borderBottom: '1px solid #B49B57' }} href="http://www.platypire.com/bob/crossroads-edited-by-christopher-conte">Platypire Reviews</a>)</div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “Crossroads: One helluva collection of writing by a group of writers who have something important to say and have no fear of saying it. Encore! (<span style={{ color: '#B49B57', textDecoration: 'none' }}> Douglas Cruickshank</span>, The Review of Arts, Literature, Philosophy and the Humanities)
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “This collection of stories by Ugandan women should be read by men, as much as by Non-African women for they are like splinters from a single shattered gourd. As an African myself I smell the heat, the dust, see the swaying hips balancing head- bearing burdens, and above all the rich earthiness of simple honesty. Each concentrates on a single shoot of reflection or incident to grow into a taller stalk. Together they are a small stand of food. Women in Africa grow food and men eat it. So should they consume these nourishing offerings. Each has a different flavor
                        [...]
                        “It is a marvelous portrait of why African women secure the traditions, educate at critical right of passage moments, and in their Sophia wisdom sway through the emotional as much as the physical landscape. It took me back to my heart’s home, and my Zulu mother who once said ‘Ow Darling, Men! What they for? Make babies, make work. Useless!.’ Yet I imagine that any Ugandan man would understand that, and celebrate his women and stand listening quite happily.” (<a style={{ color: '#B49B57', textDecoration: 'none', borderBottom: '1px solid #B49B57' }} href="https://philipparees.me/review-of-crossroadswomen-coming-of-age-in-todays-uganda/">Phillipa Rees, Letters of Love</a>)
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “Through a series of anecdotal essays written by the women involved, we are exposed to a Uganda fully stripped of the ethereal veil of “other” that Western literature so often imposes upon the region. There is certainly violence, as seen in Peace Twine’s chapter, Wife of the Enemy, but centre stage are the cultural and social struggles that most twenty-first century women can relate to, regardless of race or nationality. The issues here, while specific to Uganda and its unique history, ultimately transcend cultural barriers.
                        <br></br>
                        <br></br>
                        “From gender roles and sexuality, to child rearing, to surviving sexual assault and domestic violence, the stories in Crossroads are inspiring and relatable, and powerful in such a way as to reach beyond the situational forces which surround them. This is a book that anyone trying to find their place in the world can connect with, and if nothing else you will come away with a greater understanding of what it means to be a woman in twenty-first century Uganda.” (<span style={{ color: '#B49B57', textDecoration: 'none' }}>Ryan Collins</span>, The Bandwagon)
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “I have never enjoyed a book’s writing nearly as much as I loved the writing in this book. The vocabulary was rich and everything read as a poem. The rhythm of the book and the way specific words are chosen made this book an amazing piece of literature.” (<span style={{ color: '#B49B57', textDecoration: 'none' }}>Katherine Zhang</span>, Katherine and Books)
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “The beauty of these stories is that they are not bound by any region, but can be stories of women from any part of the globe. I recommend that both men and women pick up this book and discover stories that provide a look into the life of women who, while maybe from a particular region, their opinions, hopes and desires are truly global in nature.” (<span style={{ color: '#B49B57', textDecoration: 'none' }}>Mary George</span>, Musings of a Reader)
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “…an important collection of essays that helps us experience and distinguish the experiences of Ugandan women. We need more titles like this.” (<a style={{ color: '#B49B57', textDecoration: 'none', borderBottom: '1px solid #B49B57' }} href="https://mohadoha.com/2016/02/03/coming-of-age-in-uganda/">Mohanalakshmi Rajakumar, blog</a>)
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “The anthology succeeds beautifully, and there is no question they are rarely short on impact. It is not often that we have the opportunity to have a peek into the lives of a wide variety of women from Uganda who are not timid in exploring a plethora of topics, including some that are extremely personal while others painful. . . They are an impressive testament to the precision of Conte’s eye and his editing pencil. . .Though we may come from a different culture, we can still identify with them as these women seem remarkably like their counterparts in America and Europe.” (<a style={{ color: '#B49B57', textDecoration: 'none', borderBottom: '1px solid #B49B57' }} href="http://www.bookpleasures.com/websitepublisher/articles/7882/1/Crossroads-Women-Coming-of-Age-in-Todays-Uganda-Reviewed-By-Norm-Goldman-of-Bookpleasurescom/Page1.html#.VrUljmDSkuR">Norm Goldman, BookPleasures.com</a>)
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “The authors, writing passionately yet with measure and control, explore the nuanced reality of living as modern, Ugandan women.  The book’s brevity is powerful, because none of the stories are boring or drawn-out; the purpose of the collection is to highlight impactful parts of the women’s lives, relating to a theme. This focus ensures that the reader is not overwhelmed by an excess of information, and can freely come to their own conclusion about the importance of western values in traditional societies like Uganda.
                        <br></br>
                        <br></br>
                        “My favourite of the stories is ‘No time for pain’, which is written in the second person; it describes a woman’s isolation as she struggles to live a normal life with the memory of her war-torn childhood. Her account of grief and detachment is well-written and easy to relate to, and is complimented by her well-considered commentary on the long-term effects of war and refugee camps on the society she lives in.” (<a style={{ color: '#B49B57', textDecoration: 'none', borderBottom: '1px solid #B49B57' }} href="http://aliasalist.com/2016/01/03/the-lives-of-ugandan-women-crossroads-by-christopher-conte/">Alia’s A-List</a>)
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “I found the collection of stories written by the Ugandan women courageous and beautiful. Provides insight into the struggle women in Uganda have managing the inner conflict of rural traditional culture with the new independent urban culture and trying to balance both even though they often conflict. An astonishing read that propels you intimately into their lives.” (<a style={{ color: '#B49B57', textDecoration: 'none', borderBottom: '1px solid #B49B57' }} href="https://www.amazon.com/Crossroads-Women-Coming-Todays-Uganda/product-reviews/1507680228/ref=cm_cr_dp_see_all_btm?ie=UTF8&showViewpoints=1&sortBy=bySubmissionDateDescending">“Explorer,” Amazon.com</a>)
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “What a surprising and inspiring group of stories. This book blew me away! Honestly, I don’t read much “memoir” (which is what I sort of labeled this going in) or short stories but this book was so much more than both of those! It was captivating and touching to the point of tears in places. These women are warriors, every single one. Such an inspiring group!” (<span style={{ color: '#B49B57', textDecoration: 'none' }}>Heather Hildenbrand</span>, author of Dirty Blood, on Amazon.com)
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “Conte, a former Wall Street Journal reporter, collects 15 autobiographical essays by Ugandan women that question stereotypes of African femininity. This anthology will introduce myriad new voices, some from Uganda’s women writers’ association, FEMRITE, to Western readers. All share an interest in reconciling traditional and Western practices.
                        <br></br>
                        <br></br>
                        “A strong collection of memoiristic writing that illuminates African womanhood while blending diverse styles and experiences” (<a style={{ color: '#B49B57', textDecoration: 'none', borderBottom: '1px solid #B49B57' }} href="https://www.kirkusreviews.com/book-reviews/christopher-conte/crossroads-xrVtjmSD/">Kirkus Review</a>).
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “Crossroads is more than a book because each story has the potential to grow into a book all of its own.
                        <br></br>
                        <br></br>
                        “All the writers tackle the theme – reconciling tradition with the modern world – using simple, descriptive language that is colourful enough to draw mental pictures of the setting and evoke emotion to hook the reader. [Readers] get a visual idea and a grasp of what life in Uganda is like, its past and a glimpse into the future through their views of how certain aspects of tradition ought to be changed.
                        <br></br>
                        <br></br>
                        “I recommend this book, first, to women in Uganda today so that they appreciate the position they hold in society and the power to change what harms them by choosing to respect and reshape tradition to suit their lives today. Africans, as a whole, are also welcome to try this book because the theme ripples across the continent – tradition versus life today – which is the intra conflict within each one of us.” (<span style={{ color: '#B49B57', textDecoration: 'none' }}>Jackie Nalubwama</span>, The New Vision).
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “Crossroads is an intriguing collection of human experiences that engage yet mesmerize the reader. A very good read that educates society about what different cultural experiences can bring to life. Mr. Conte explicitly nurtures the stories in a fantastic yet delicate basket. This is a cultural keepsake. (<span style={{ color: '#B49B57', textDecoration: 'none' }}>Mazzi Wampamba</span>, author of “Like an Ocean: Poems in Prose”).
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “A beautiful and thoughtful series of essays” that brings the Ugandan experience into “stunning relief . . . The collection is no-holds-barred: these women critique traditional culture, Western influence, the bureaucratic bloatedness of NGOs, religion, and gender roles, all with clarity and nuance. . . . The result is a well-rounded, compelling and edifying picture of the challenges that women face in modern Africa.” (<a style={{ color: '#B49B57', textDecoration: 'none', borderBottom: '1px solid #B49B57' }} href="https://www.forewordreviews.com/reviews/crossroads-women-coming-of-age-in-todays-uganda/">Michelle Anne Schingler, Clarion Foreword Reviews</a>. Schlingler awards Crossroads the magazine’s top, five-star rating — an honor the journal confers on just 8% of the books it reviews.
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “Chilling, thrilling . . . stories of empowered Ugandan women. [Crossroads] presents the challenges they go through to accommodate their newly-found strength. The writers address issues dear to the typical Ugandan woman.” (<a style={{ color: '#B49B57', textDecoration: 'none', borderBottom: '1px solid #B49B57' }} href="https://www.observer.ug/lifestyle/42-entertainment/38981-can-the-modern-woman-follow-the-ssengas-counselling">Andrew Kaggwa, The Observer</a>)
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “A magnificent work. One of the most important things we are here to do no matter where we are on earth is to witness to life.  . . . The lives of African women are really complicated because we are witnessing to life through a lens that says “different.” The lens says “different” — you’re female you’re not male; the lens says “different” — you’re African you’re not from anywhere else. You have really witnessed to this difference in the most magnificent manner.”  (<a style={{ color: '#B49B57', textDecoration: 'none', borderBottom: '1px solid #B49B57' }} href="https://www.youtube.com/watch?v=5z556swcZac">Tsitsi Dangarembga</a>, Zimbabwean author who wrote “Nervous Conditions” and other acclaimed works)
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        A riveting read. Crossroads is a window on a subset of African women that banishes cliché. How can anyone read the testimonies in this book and look at Africa in the same old, same old way again?! (<span style={{ color: '#B49B57', textDecoration: 'none' }}>Julia Royall</span>, Global Health Information Specialist, Fulbright Scholar)
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “Perhaps the most insightful and enlightening non-fiction publication yet about the search for identity among women in Uganda… The authors are not afraid to show their vulnerability, and admit to their ongoing soul-searching… Most of the (relatively well-educated, urban) women do not necessarily end up embracing ‘Western’ values whilst rejecting ‘African’ ones, or the other way around. Rather, they shape their identities around aspects from either side that they deem relevant, attractive and useful. Presents a very interesting insight into the many challenges, opportunities and dilemmas that women in Uganda are confronted with these days — how to relate to Western influences, to the village life, to men, to society’s more ‘traditional’ expectations, and so on. This issue of ‘modernization and traditionalism’ is a dominant theme in urban Africa.” (<span style={{ color: '#B49B57', textDecoration: 'none' }}>Mark Schenkel</span>, Africa regional correspondent for Het Financieele Dagblad, The Netherlands)
                    </div>
                </div>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>
                        “Gives a real sense of the breadth of issues Ugandan women face today. I appreciated the many explorations of identity, the description of mourning, the importance of names, perspectives on the ‘developed’ world approaches to African countries, and the wonderful laying out of the issues in the ‘complicated landscape’ of Western ways and African ways, and NGOs. From the joyful to the terrifying, what comes through in this collection is the intelligence and thoughtfulness with which the authors articulate their struggles and manifest a heartening strength and resilience.” (<span style={{ color: '#B49B57', textDecoration: 'none' }}>Candace Wood</span>, Amazon.com customer).
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Crossroadreviews