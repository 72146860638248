import * as React from 'react';
import { Helmet } from 'react-helmet'
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { ClassNames } from '@emotion/react';
import { Link } from 'react-router-dom'
import { Link as Link1 } from 'react-router-dom'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import amazon from '../pics/Amazon_PNG6.png'
import buypic from '../pics/crossroadsbuypic.jpeg'

const useStyles = makeStyles((theme) => ({
    body: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            position: 'center',
        },

        width: '100vw',
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    goBack: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '7vmin',
            marginTop: '5vh',
        },
        maxWidth: '100vw',
        marginTop: '2%',
        marginLeft: '3%',
        display: 'flex',
        alignItems: 'center',
        color: '#5a2a20',
        fontFamily: 'oswald',
    },
    goBackText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
        },
        fontSize: '1.8em'
    },

    backIcon: {
        [theme.breakpoints.down('sm')]: {
            transform: 'scale(2)',
            marginRight: '5vw'
        },
        "&:hover": {
            color: 'white',
            transform: 'scale(2.4)',
            cursor: 'pointer'
        },
        marginRight: '3vmin',
        transform: 'scale(2.4)'
    },
    titleContainer: {
        [theme.breakpoints.down('md')]: {
            fontSize: '10vmin',
            paddingBottom: '7vmin',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        },
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'oswald',
        color: '#5a2a20',
        fontSize: '8vmin',
        marginTop: '2vh',
        paddingBottom: '4vmin',
        WebkitTextStroke: '2px',
        WebkitTextStrokeColor: '#b68f72',
        textShadow: 'black 1px 0 5px'
    },
    title: {
        color: 'white',
        fontSize: '5vmin',
        borderBottom: '3px solid white',
        width: '100%'
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
            paddingBottom: '7vmin',
            width: '95vw',
        },

        backgroundColor: '#5a2a20',
        padding: '2vh',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)',
        borderRadius: '10px',
        marginRight: '2vw'
    },
    option1: {
        [theme.breakpoints.down('sm')]: {
            height: '100%'
        },
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        fontFamily: 'oswald',
        height: '15vh',
        marginBottom: '5%'
    },
    option2: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        fontFamily: 'oswald',
        height: '100%'
    },
    details: {
        display: 'flex',
        alignContent: 'center',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        paddingTop: '3vmin'
    },
    img: {
        [theme.breakpoints.down('sm')]: {
            width: '20vmin',
            marginTop: '2vh'
        },
        paddingTop: '2vh',
        width: '12vmin',
        backgroundColor: 'white',
        padding: '1vmin'

    },
    details2: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '3vmin',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        paddingLeft: '2vw',
        paddingRight: '2vw'
    },
    list: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
            marginLeft: '2vw'
        },
        display: 'flex',
        flexDirection: 'column',
        fontSize: '2vmin'
    },
    li: {
        paddingBottom: '.5vh',

    },
    horizontal: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100%',
            position: 'center',
        },
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        justifyContent: 'center'
    },
    img2: {
        [theme.breakpoints.down('md')]: {
            width: '60vmin',
            marginRight: '0'
        },
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
        width: '70vmin'
    },
    buypic: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginTop: '4vmin'
        },
    },
    span: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vmin',
            color: '#5a2a20',
            fontWeight: 'bold',
        },
        color: '#5a2a20',
        fontWeight: 'bold',
    }
}))

const Crossroadsbuy = ({ crossroads, setCrossroads }) => {

    useEffect(() => {
        setCrossroads(true)
    }, [])

    const classes = useStyles()

    return (
        <div className={classes.body}>
            <Helmet>
                <title>BUY</title>
                <meta
                    name="description"
                    content="Where to Buy Crossroads"
                />
            </Helmet>
            <div className={classes.goBack}>
                <Link1 to="/crossroadshome" style={{ color: 'inherit', textDecoration: 'none' }}>
                    <ArrowCircleLeftOutlinedIcon size="small" className={classes.backIcon} />
                </Link1> <div className={classes.goBackText}>BACK TO CROSSROADS HOME</div>
            </div>
            <div className={classes.titleContainer}>
                BUY
            </div>
            <div className={classes.horizontal}>
                <div className={classes.container}>
                    <div className={classes.option1}>
                        <div className={classes.title}>
                            ONLINE / WORLDWIDE
                        </div>
                        <div className={classes.details}>
                            <a target="_blank" href='https://www.amazon.com/Crossroads-Women-Coming-Todays-Uganda-ebook/dp/B014QBPALM/ref=sr_1_2?qid=1679899239&refinements=p_27%3AChristopher+Conte&s=digital-text&sr=1-2&text=Christopher+Conte'>
                                <img className={classes.img} src={amazon}></img>
                            </a>
                        </div>
                    </div>
                    <div className={classes.option2}>
                        <div className={classes.title}>
                            IN UGANDA
                        </div>
                        <div className={classes.details2}>

                            Available now at the following retailers:

                            <ul className={classes.list}>
                                <li className={classes.li}>
                                    <span style={{ color: 'white' }}>Aristoc</span> (Garden City and Acacia Mall)
                                </li>
                                <li className={classes.li}>
                                    <span style={{ color: 'white' }}>Bookpoint</span> (Village Mall, Bugolobi)
                                </li>
                                <li className={classes.li}>
                                    <span style={{ color: 'white' }}>Uganda Book Shop</span> (Colville Street)
                                </li>
                                <li className={classes.li}>
                                    <span style={{ color: 'white' }}>Uganda National Museum</span> (Kira Road)
                                </li>
                                <li className={classes.li}>
                                    <span style={{ color: 'white' }}>Makerere University Book Shop</span>
                                </li>
                                <li className={classes.li}>
                                    <span style={{ color: 'white' }}>Entebbe Airport Bookshop and Femrite</span> (Kira Road)
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div className={classes.buypic}>
                    <img className={classes.img2} src={buypic}></img>
                </div>
            </div>
        </div>
    )
}

export default Crossroadsbuy