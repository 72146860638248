import * as React from 'react';
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import bookRender from '../pics/newcrcover.png'

import { Button } from '../MUI'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

import newBanner from '../pics/finalbanner.jpg'
import cover2 from '../pics/rtfcover2 copy.png'
import cLogo from '../pics/crossroadsremoved.png'

const useStyles = makeStyles((theme) => ({
    body: {
        width: '100%',
        height: '100%',
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundColor: '#5a2a20',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    heroContainer: {
        [theme.breakpoints.down('xs')]: {
            // display: 'none',
        },
        position: 'relative',
    },
    heroRetry: {
        content: `url('${newBanner}')`,
        width: '100vw',
    },
    scroller: {
        color: 'white',
        position: 'fixed',
        bottom: '5vh',
        right: '3vw',
        "&:hover": {
            color: 'blue',
            cursor: 'pointer'
        },
    },
    scrollHolder: {
        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },
        marginRight: '7vw', position: 'absolute', bottom: '0', right: '0', marginBottom: '5vh',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontFamily: 'poppins',
        color: '#5a2a20',
        fontSize: '3vmin'
    },
    scrollText: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        paddingRight: '1.5vw'
    },
    upperContainer: {
        [theme.breakpoints.down('lg')]: {
            marginTop: '5vh',
            height: '100%'
        },
        [theme.breakpoints.down('md')]: {
            marginTop: '2vh',

            height: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '2.5vh',
            paddingTop: '3vmin'
        },
        height: '100vh',
        minWidth: '100vw',
    },
    bookandtextContainer: {
        [theme.breakpoints.down('md')]: {
            marginBottom: '5%',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100vw',
        },
        height: '90%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    blurbContainer: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.3em',
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            width: '90vw',
            fontSize: '1.3em'
        },

        fontSize: '2vmin',
        fontFamily: 'Roboto Serif, serif',
        color: "white"
    },
    buttonHolder: {
        [theme.breakpoints.down('md')]: {
            paddingBottom: '1.5vh'
        },
        [theme.breakpoints.down('xs')]: {
            // display: 'none',
            marginBottom: '0'
        },
        marginBottom: '1vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    // mobileButtonHolder: {
    //     [theme.breakpoints.up('xs')]: {
    //         display: 'none'
    //     },
    //     [theme.breakpoints.down('xs')]: {
    //         display: 'flex',

    //     },
    //     display: 'flex',
    //     width: '100%',
    //     justifyContent: 'center',

    // },

    button: {
        width: '100%',
    },
    btn: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '5vh',
            width: '100%'
        },
        width: '100%',
        display: 'flex',
    },
    center: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: '2vh',
            width: '100vw',
            paddingLeft: '0'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            paddingLeft: '0'
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '45vw',
        paddingLeft: '10vw',

    },
    // cbookRender: {
    //     [theme.breakpoints.down('xs')]: {
    //         marginBottom: '5vh',
    //     },
    //     height: '65vmin',
    //     marginBottom: '3vh'
    // },
    bookbuttonBackground: {
        [theme.breakpoints.down('xs')]: {
            height: 'max-content',
            paddingBottom: '0vmin',
            paddingTop: '2vmin',
            backgroundColor: '#5a2a20',
            boxShadow: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            height: 'max-content',
            paddingBottom: '2vh',
            paddingTop: '2vmin',
            backgroundColor: '#5a2a20',
            boxShadow: 'none'
        },

        backgroundColor: '#b68f72',
        height: '70%',
        justifyContent: 'center',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)'

    },
    bookandButton: {
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '0vh'
        },
        display: 'flex',
        flexDirection: 'column',
    },

    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },


    crossroadsContainer: {
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            marginTop: '5vh',
            position: 'relative'
        },
        height: '100vh',
        width: '100vw',
        position: 'relative',
    },
    bookWrap: {
        height: '90%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    arrow: {
        "&:hover": {
            color: 'white',
            transform: 'scale(2.4)',
            cursor: 'pointer'
        },
    },
    scrollDown: {
        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },

        width: '100vw',
        float: 'right',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        alignItems: 'center',
        marginRight: '7vw',
        color: 'white',
        fontSize: '2vmin',

    },
    scrollTop: {
        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },
        width: '100vw',
        float: 'right',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        alignItems: 'center',
        marginRight: '7vw',
        color: 'white',
        fontSize: '2vmin',

    },
    navText: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
        fontFamily: 'Oswald',
        marginRight: '1%',
        color: 'white',
        fontSize: '2vmin',
    },
    navText2: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
        fontFamily: 'Oswald',
        marginRight: '1%',
        color: 'white',
        fontSize: '2vmin',
    },
    bookRender: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: '.5vh'
        },
        marginBottom: '3vh',
        height: '55vmin',
    },
    cbookRender: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: '.5vh'
        },
        marginTop: '1.5vh',
        marginBottom: '3vh',
        height: '55vmin',
    },
    crossroadsSideHolder: {
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '3vh',
        },
        display: 'flex',
        flexDirection: 'column',


    },
    crossroadsTitle: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '5.5vmin'
        },
        [theme.breakpoints.down('lg')]: {
            marginBottom: '4vh'
        },
        [theme.breakpoints.down('md')]: {
            marginTop: '10vh',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '5vh',
            marginBottom: '1vh',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '8vmin'
        },
        marginTop: '3vh',
        fontFamily: 'oswald',
        fontSize: '3.5vmin',
        color: '#b68f72',
        marginBottom: '5vh',
    },

    //NEW CODE
    horizontalHolder: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '3vh'
    },
    bookandClick: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

    },
    clickHere: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '3vh',
            marginBottom: '4vh',
            fontSize: '.8em'
        },
        color: 'white',
        fontSize: '1.5vmin',

        fontFamily: 'Roboto Serif, serif',
        color: "white",
    },
    bookContainer: {
        [theme.breakpoints.down('md')]: {
            marginBottom: '5%',
        },
        [theme.breakpoints.down('sm')]: {

            width: '100vw',
        },
        width: '50vw',
        height: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

    },
    bookBlurb: {
        [theme.breakpoints.down('sm')]: {

            width: '90vw',
            fontSize: '1.3em'
        },
        fontSize: '1.75vmin',
        fontFamily: 'Roboto Serif, serif',
        color: 'white',
        width: '30vw'
    },
    cLogo: {
        // [theme.breakpoints.down('md')]: {
        //     width: '20vh'
        // },
        [theme.breakpoints.down('sm')]: {

            width: '30%',
        },
        width: '20vmin',
        filter: 'brightness(0) invert(1)',
        marginBottom: '3vh'
    },
    // bookBlurb: {
    //     [theme.breakpoints.down('lg')]: {
    //         width: '75vw'
    //     },
    //     [theme.breakpoints.down('sm')]: {
    //         justifyContent: 'center',
    //         width: '90vw',
    //         fontSize: '1.3em',
    //         paddingLeft: '0vw'
    //     },
    //     display: 'flex',
    //     justifyContent: 'center',
    //     width: '100%',

    //     fontSize: '1.75vmin',
    //     fontFamily: 'Roboto Serif, serif',
    //     color:"white",

    //     // marginBottom:'3vh'
    //     // paddingLeft: '10vw',
    // },
    // clickHere: {
    //     [theme.breakpoints.down('sm')]: {
    //         marginTop: '0',
    //         display: 'flex',
    //         justifyContent: 'center',
    //          fontSize: '4vmin'
    //     },
    //     width: '100%',
    //     display:'flex',

    //     // justifyContent: 'center',????
    //     fontSize: '2vmin',

    //     fontFamily: 'Roboto Serif, serif',
    //     color:"white",

    //     // width: '45vw',
    //     marginTop:'5vh'
    // }
    // //END NEW CODE
    reviewQuote: {
        width: '100%',
        display: 'flex',
        fontStyle: 'italic',
        flexDirection: 'column',
        textAlign: 'center'
    },
    quoteAtt: {
        [theme.breakpoints.down('sm')]: {
            // whiteSpace: 'nowrap',
            textAlign: 'center',
            fontSize: '.8 em'
        },
        fontStyle: 'normal',
        width: '100%',
        textAlign: 'center'
    }

}))


const Homepage = ({ crossroads, setCrossroads }) => {

    useEffect(() => {
        setCrossroads(false)
    }, [])

    const classes = useStyles();

    const scroll = function () {
        const element = document.getElementById('section-1')
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const scrollTwo = function () {
        const element = document.getElementById('section-2')
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const scrollThree = function () {
        const element = document.getElementById('section-landing')
        if (element) {
            window.scrollTo({ top: '0', behavior: 'smooth' })
        }
    }

    return <>
        <Helmet>
            <title>Remembering the Future</title>
            <meta
                name="description"
                content="An Exciting New Book about Uganda's Changing Culture"
            />
            <meta
                name="keywords"
                content="Remembering The Future, Africa, Uganda, African Culture"
            />
        </Helmet>
        <div className={classes.body}>
            <div className={classes.heroContainer} id='section-landing'>


                <div className={classes.heroRetry}></div>
                <div className={classes.scrollHolder}>
                    <div className={classes.scrollText}>Learn More!</div>
                    <ArrowCircleDownIcon className={classes.arrow} sx={{ transform: 'scale(2.0)', display: { s: 'none', lg: 'block', xl: 'block' } }} onClick={scroll} />
                </div>
            </div>

            <div className={classes.upperContainer} id='section-1'>
                <div className={classes.bookandtextContainer}>
                    <div className={classes.bookbuttonBackground}>
                        <div className={classes.bookandButton}>
                            <img className={classes.bookRender} src={cover2}></img>
                            <div className={classes.buttonHolder}>
                                <a target="_blank" href='https://www.amazon.com/dp/B0C7T1NQQ5/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1686772217&sr=8-1' style={{ textDecoration: 'none' }} class={classes.btn}><Button variant="contained" className={classes.button} sx={{ width: '100%' }}>Buy It Now</Button></a>
                            </div>
                        </div>
                    </div>
                    <div className={classes.center}>
                        <div className={classes.blurbContainer}>
                            A psychiatrist teaches medical students lessons he learned from his mother, a traditional healer. A
                            popular film maker explores traditional folklore in martial arts movies. An aging patriarch presides over
                            his family according to principles handed down by ancestors. In these and other stories in this wide-ranging collection, Ugandan writers paint a vivid and moving portrait of today’s Africa.
                            <br></br>
                            <br></br>
                            The writers offer unblinking criticism where they see social failure. Population growth and the cash
                            economy have devastated once idyllic villages. Communities are gripped in a culture war pitting
                            missionaries against spiritual healers. A pastoralist tribe that withstood colonial rulers now finds its
                            cherished way of life vanishing. And a woman’s spiritual crisis reflects widespread religious conversions.
                            <br></br>
                            <br></br>
                            Yet values undermined by colonialism and globalization still echo across this verdant land. A respected
                            journalist believes surviving ancient clans hold the answers to social decay and environmental
                            destruction. A young man learns the meaning of leadership and character from a line of powerful
                            matriarchs. And an aging woman in hill country proudly recalls her arranged marriage as a moral
                            triumph.
                            <br></br>
                            <br></br>
                            These tales of resilience, dignity and moral vision speak not just to Ugandans eager to chart their own
                            way in today’s world. They contain lessons for all people everywhere.
                            <br></br>
                            <br></br>
                            <div className={classes.reviewQuote}>
                            <span style={{color: '#b68f72'}}>"The most outstanding book that Uganda has yet mothered"</span>
                            
                            <br></br>
                            <div className={classes.quoteAtt}><span style={{color: '#b68f72'}}>- Timothy Wangusa</span>
                            <br></br>Novelist, Poet and Educator
                            
                            </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className={classes.scrollDown}>
                    <div className={classes.navText}>Related Reading</div><ArrowCircleDownIcon className={classes.arrow} sx={{ marginLeft: '1vw', transform: 'scale(2.0)', display: { s: 'none', xs: 'none', md: 'block', lg: 'block', xl: 'block' } }} onClick={scrollTwo} />
                </div>
            </div>

            <div className={classes.crossroadsContainer} id='section-2'>
                <div className={classes.bookWrap}>
                    <div className={classes.bookContainer}>
                        <div className={classes.crossroadsTitle}>
                            ANOTHER MUST READ
                        </div>
                        <div className={classes.horizontalHolder}>
                            <div className={classes.bookandClick}>
                                <Link onClick={() => {
                                    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    setCrossroads(true)
                                }} to='/crossroadshome'><img className={classes.cbookRender} src={bookRender}></img></Link>
                                <div className={classes.clickHere}>
                                    Click the Image to View the Crossroads Page
                                </div>
                            </div>

                            <div className={classes.crossroadsSideHolder}>
                                <img className={classes.cLogo} src={cLogo}></img>
                                <div className={classes.bookBlurb}>
                                    Ugandan women stand at an intersection where tradition and the “modern” world meet. In "Crossroads," they explore their pasts and share their hopes for the future. Discussing topics ranging from religion to sports, and moving between quaint rural villages and bustling Kampala, they weigh their society's rich traditions against changing attitudes about sex, medicine, and women's many roles -- and explore how the virtues of the past can be preserved in a time of unprecedented social change.
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className={classes.scrollTop}>
                    <div className={classes.navText2}>Return To Top</div><ArrowCircleUpIcon className={classes.arrow} sx={{ marginLeft: '1vw', transform: 'scale(2.0)', display: { lg: 'block', xl: 'block' } }} onClick={scrollThree} />
                </div>

            </div>

        </div>
    </>
}

export default Homepage
