import * as React from 'react';
import { Helmet } from 'react-helmet'
import {makeStyles} from '@material-ui/core/styles'
import {useState, useEffect} from 'react'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { Link as Link1 } from 'react-router-dom'


const Afiaasinde= ({crossroads, setCrossroads}) => {



    const useStyles = makeStyles((theme) =>({
        body: {
            height: '100%',
            minHeight: '100vh',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
        },
        titleContainer: {
            [theme.breakpoints.down('md')]:{
                fontSize: '6vw',
                paddingLeft: '2vmin',
                paddingRight: '2vmin',
                paddingBottom: '7vmin',
                WebkitTextStroke: '0px',
                textShadow:'black 0px 0 0px'
                },
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems:'center',
                fontFamily: 'oswald',
                color: '#5a2a20',
                fontSize: '6vmin',
                marginTop: '6vh',
                paddingBottom: '4vmin',
           },
        descriptionContainer: {
            display: 'flex',
            width: '100vw',
            justifyContent: 'center'
        },
        description: {
            [theme.breakpoints.down('md')]: {
                fontSize: '4vw'
                },
            display: 'flex',
            justifyContent: 'center',
            width: '90vw',
            paddingTop: '2vh',
            paddingBottom: '2vh',
            fontSize:'1.5vw',
            fontStyle: 'italic',
            color: 'white'
        },
        videoContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '100vw',
        },
        responsiveiFrame: {
            [theme.breakpoints.down('md')]: {
            width: '90vw',
            height: '54vw'
            },
         width: '40vw',
         height: '24vw'
        }
    }))

    useEffect(() => {
        setCrossroads(false)
    }, [])

    const classes = useStyles()

    return <>
    <div className={classes.body}>
        <Helmet>
            <title>REGINA ASINDE ON THE AFIA PODCAST</title>
                <meta
                    name="description"
                    content="Watch Guadensia Tumwesigire sing The Bride's Song"
                />
                <meta
                    name="keywords"
                    content="Uganda, Ugandan Culture, Guadensia Tumwesigire"
                />
        </Helmet>
        <div className={classes.title}>
                
        <div className={classes.titleContainer}>
            REGINA ASINDE ON THE AFIA PODCAST
        </div>
        </div>
        <div className={classes.videoContainer}>
            <iframe className={classes.responsiveiFrame} src="https://www.youtube.com/embed/hSDScM5B1fU?si=nAXc3QGczZNeHNrO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
    </>
    
}

export default Afiaasinde