import * as React from 'react';
import { Helmet } from 'react-helmet'
import { useState, useEffect } from 'react'
import { Button } from '../MUI';
import { makeStyles } from '@material-ui/core/styles'
import { Link as Link1 } from 'react-router-dom'

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import bookRender from '../pics/newcrcover.png'

const useStyles = makeStyles((theme) => ({
    body: {
        width: '100%',
        height: '100%',
        minHeight: '95vh',
        minWidth: '100vw',
        backgroundColor: '#5a2a20',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        
    },
    upperContainer: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: '7vh',
            paddingBottom: '3vh'
        },
        // paddingTop: '1.5vh',
        // height: '90%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    bookandButton: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '4vh'
        },
        display: 'flex',
        flexDirection: 'column',
    },
    buttonHolder: {
        [theme.breakpoints.down('md')]: {
            paddingBottom: '1.5vh'
        },
        [theme.breakpoints.down('xs')]: {
            // display: 'none',
            marginBottom: '0'
        },
        marginBottom: '1vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    // mobileButtonHolder: {
    //     [theme.breakpoints.up('sm')]: {
    //         display: 'none'
    //     },
    //     [theme.breakpoints.down('sm')]: {
    //         display: 'flex'
    //     },
    //     width: '100%',
    //     justifyContent: 'center'
    // },

    button: {
        width: '100%'
    },
    btn: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '0',
            width: '100%'
        },
        width: '100%',
        display: 'flex',
    },
    navContainer: {
        [theme.breakpoints.down('md')]: {
            fontSize: '2vw',
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '6vmin',
            width: '100vw',
            marginTop: '3vh'
        },

        color: '#b68f72',
        paddingTop: '2vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    navTitle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '7vmin'
        },
        fontSize: '3vmin',
        fontFamily: 'oswald',
        paddingBottom: '.5vh',
    },
    ul: {
        [theme.breakpoints.down('md')]: {
            marginTop: '3vh',
            marginBottom: '3vh'
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: '2vw',
        width: '100%'
    },
    bookandtextContainer: {
        [theme.breakpoints.down('md')]: {
            alignItems: 'stretch',
            width: '100%',
            paddingLeft: '2vw',
            paddingRight: '2vw'
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100vw',
            alignItems: 'center'
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },

    center: {

        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            paddingLeft: '0'
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50vw',
        paddingLeft: '5vw'
    },
    cbookRender: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: '5vh',
        },
        height: '65vmin',
        marginBottom: '3vh',
    },
    bookbuttonBackground: {
            [theme.breakpoints.down('lg')]: {
                height: 'max-content'
            },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '0vmin',
            paddingTop: '2vmin',
            backgroundColor: '#5a2a20',
            boxShadow: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            
            
            paddingBottom: '2vh',
            paddingTop: '2vmin',
            backgroundColor: '#5a2a20',
            boxShadow: 'none'
        },

        backgroundColor: '#b68f72',
        height: '100%',
        justifyContent: 'center',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)'
    },
    bookandButton: {
        [theme.breakpoints.down('md')]: {
            display: 'block',

        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '0vh'
        },
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center'
    },
    divider: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '1vh',
        paddingBottom: '1vh'
    },
    dividerImg: {
        [theme.breakpoints.down('sm')]: {
            width: '50vw',
            paddingTop: '3vh',
            paddingBottom: '3vh'
        },
        width: '50%'
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    blurbContainer: {

        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '90vw',
            fontSize: '4vmin'
        },
        color: 'white',
        fontSize: '2vmin',
        fontFamily: 'Roboto Serif, serif',
    },
    quoteContainer: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: '5vmin'
        },
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        paddingTop: '1vh',
        color: 'white'
    },
    quote: {
        [theme.breakpoints.down('md')]: {

            textAlign: 'right',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90vw',
            fontSize: '4vmin',
            paddingBottom: '2vh',
            textAlign: 'left',
        },

        paddingTop: '1vh',
        paddingBottom: '2vh',
        fontFamily: 'Playfair Display, serif',
        fontSize: '2vmin'
    },
    quoteAtt: {
        [theme.breakpoints.down('md')]: {
            marginBottom: '5vh',
            textAlign: 'right',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vmin',
            paddingTop: '4vh'

        },
        float: 'right',

        fontSize: '2vmin',
        paddingTop: '2vh'
    },
    options: {
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            width: '100%',
            marginTop: '.5vh',
            alignContent: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '50vw',

            marginTop: '1vh'
        },
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        borderTop: '1px solid #5a2a20',
    },
    li: {
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.5vw'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '2.5vw',
            marginTop: '0vh',
            marginLeft: '38%',
            width: 'max-content'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '30%',
            marginTop: '2vh',
            fontSize: '5vmin'
        },
        "&:hover": {
            transform: 'scale(1.2)',
            cursor: 'pointer',
            color: 'white'
        },
        fontFamily: 'oswald',
        fontSize: '2.4vmin',
        padding: '1vw',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

    },
    popup: {
        [theme.breakpoints.down('md')]: {
        },
        "& .MuiPaper-root": {
            backgroundColor: '#b68f72'
        }
    }
}))

const Crossroadshome = ({ crossroads, setCrossroads }) => {

    useEffect(() => {
        setCrossroads(true)
    }, [])

    const classes = useStyles()

    const [toggleMenu, setToggleMenu] = useState(false)

    const [anchorE, setAnchorE] = React.useState(null);
    const openMenu = Boolean(anchorE);
    const handleSelect = (event) => {
        setAnchorE(event.currentTarget)
        setToggleMenu(true)
    }
    const handleCloseSelect = () => {
        setAnchorE(null);
        setToggleMenu(false)
    };

    return <>
        <div className={classes.body}>
            <Helmet>
                <title>CROSSROADS</title>
                <meta
                    name="description"
                    content="Crossroads: Women in Modern Uganda "
                />
                <meta
                    name="keywords"
                    content="African Culture, African Authors, Ugandan Authors, Ugandan Women"
                />
            </Helmet>
            <div className={classes.upperContainer}>
                <div className={classes.bookandtextContainer}>
                    <div className={classes.bookbuttonBackground}>


                        <div className={classes.bookandButton}>


                            <img className={classes.cbookRender} src={bookRender}></img>
                            <div className={classes.buttonHolder}>
                                <a target="_blank" href='https://www.amazon.com/Crossroads-Women-Coming-Todays-Uganda-ebook/dp/B014QBPALM/ref=sr_1_2?qid=1679899239&refinements=p_27%3AChristopher+Conte&s=digital-text&sr=1-2&text=Christopher+Conte' style={{ textDecoration: 'none' }} class={classes.btn}><Button variant="contained" className={classes.button}>Buy It Now</Button></a>
                            </div>
                        </div>
                    </div>
                    <div className={classes.center}>
                        <div className={classes.blurbContainer}>
                            {/* <div className={classes.mobileButtonHolder}>
                                <a target="_blank" href='https://www.amazon.com/Crossroads-Women-Coming-Todays-Uganda-ebook/dp/B014QBPALM/ref=sr_1_2?qid=1679899239&refinements=p_27%3AChristopher+Conte&s=digital-text&sr=1-2&text=Christopher+Conte' class={classes.btn} style={{ textDecoration: 'none' }}><Button variant="contained" className={classes.button} >Buy It Now</Button></a>
                            </div> */}
                            Ugandan women stand at an intersection where tradition and the “modern” world meet. In "Crossroads," they explore their pasts and share their hopes for the future. Discussing topics ranging from religion to sports, and moving between quaint rural villages and bustling Kampala, they weigh their society's rich traditions against changing attitudes about sex,  medicine, and women's many roles -- and explore how the virtues of the past can be preserved in a time of unprecedented social change.
                        </div>

                        <div className={classes.navContainer}>
                            <div className={classes.ul}>
                                <div className={classes.navTitle}>
                                    LEARN MORE
                                </div>
                                <div className={classes.options}>
                                    <div className={classes.li}><Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}
                                        to="/crossroadsbuy" style={{ color: 'inherit', textDecoration: 'none' }}>BUY</Link1></div>
                                    <div className={classes.li}><Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}
                                        to="/crossroadsexcerpt" style={{ color: 'inherit', textDecoration: 'none' }}>EXCERPT</Link1></div>
                                    <div className={classes.li}><Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}
                                        to="/crossroadschapters" style={{ color: 'inherit', textDecoration: 'none' }}>CHAPTERS</Link1></div>
                                    <div className={classes.li}><Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}
                                        to="/crossroadsauthors" style={{ color: 'inherit', textDecoration: 'none' }}>AUTHORS</Link1></div>
                                    <div className={classes.li}
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleSelect}
                                    >GALLERY{toggleMenu ? <ArrowDropUpIcon /> : <ArrowRightIcon />}</div>

                                    <Menu
                                        className={classes.popup}
                                        id="basic-menu"
                                        anchorEl={anchorE}
                                        open={openMenu}
                                        onClose={handleCloseSelect}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}

                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <Link1 to="/nakisanzevideo" style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelect}>Nakisanze Segawa: Religious Traditionalism</MenuItem></Link1>
                                        <Link1 to="/sophielooksback" style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelect}>Sophie Bamwoyeraki Looks Back</MenuItem></Link1>
                                        <Link1 to="/chrisconte" style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelect}>Chris Conte on the idea of Crossroads</MenuItem></Link1>
                                        <Link1 to="/visitwithhilda" style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelect}>A Visit with Hilda Twongyeirwe</MenuItem></Link1>
                                        <Link1 to="/tsitsi" style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelect}>Tsitsi Dangarembga on Crossroads</MenuItem></Link1>
                                        <Link1 to="/lydia" style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelect}>Lydia Namubiru and Chris Conte on CCTV</MenuItem></Link1>
                                        <Link1 to="/crossroadsblhighlights" style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelect}>Book Launch Videos</MenuItem></Link1>
                                        <Link1 to="/crossroadsgallery" style={{ textDecoration: 'none', color: 'white' }}><MenuItem onClick={handleCloseSelect}>Book Launch Photos</MenuItem></Link1>
                                    </Menu>

                                    <div className={classes.li}><Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}
                                        to="/crossroadsreviews" style={{ color: 'inherit', textDecoration: 'none' }}>REVIEWS</Link1></div>
                                    <div className={classes.li}><Link1 onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                                    }}
                                        to="/crossroadsblog" style={{ color: 'inherit', textDecoration: 'none' }}>BLOG</Link1></div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.quoteContainer}>
                            <div className={classes.quote}>
                                <span style={{ fontStyle: 'italic' }}>“Crossroads: One helluva collection of writing by a group of writers who have something important to say and have no fear of saying it. Encore!</span>

                                <br></br>
                                <div className={classes.quoteAtt}>-&nbsp;<span style={{ color: '#B49B57' }}>Douglas Cruickshank</span>, The Review of Arts, Literature, Philosophy and the Humanities</div>
                            </div>
                            <div className={classes.quote}>
                                <span style={{ fontStyle: 'italic' }}>I have never enjoyed a book’s writing nearly as much as I loved the writing in this book. The vocabulary was rich and everything read as a poem. The rhythm of the book and the way specific words are chosen made this book an amazing piece of literature.”</span>

                                <br></br>
                                <div className={classes.quoteAtt}>-&nbsp;<span style={{ color: '#B49B57' }}>Katherine Zhang</span>, Katherine and Books</div>
                            </div>
                            <div className={classes.quote}>
                                <span style={{ fontStyle: 'italic' }}>A riveting read. Crossroads is a window on a subset of African women that banishes cliché. How can anyone read [...] this book and look at Africa in the same old, same old way again?!</span>

                                <br></br>
                                <div className={classes.quoteAtt}>-&nbsp;<span style={{ color: '#B49B57' }}>Julia Royall</span>, Global Health Information Specialist, Fulbright Scholar</div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>


        </div>
    </>


}

export default Crossroadshome