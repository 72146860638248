import * as React from 'react';
import { Helmet } from 'react-helmet'
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Link as Link1 } from 'react-router-dom'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

import hilda from '../pics/hilda.png'
import bunyonyi from '../pics/LakeBunyonyi.png'
import boatman from '../pics/boatman.png'

const useStyles = makeStyles((theme) => ({
    goBack: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '7vmin',
            marginTop: '3vh',
        },
        maxWidth: '100vw',
        marginTop: '2%',
        marginLeft: '3%',
        display: 'flex',
        alignItems: 'center',
        color: '#5a2a20',
        fontFamily: 'oswald',
    },
    goBack: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '7vmin',
            marginTop: '5vh',
        },
        maxWidth: '100vw',
        marginTop: '2%',
        marginLeft: '3%',
        display: 'flex',
        alignItems: 'center',
        color: '#5a2a20',
        fontFamily: 'oswald',
    },
    goBackText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
        },
        fontSize: '1.8em'
    },

    backIcon: {
        [theme.breakpoints.down('sm')]: {
            transform: 'scale(2)',
            marginRight: '5vw'
        },
        "&:hover": {
            color: 'white',
            transform: 'scale(2.4)',
            cursor: 'pointer'
        },
        marginRight: '3vmin',
        transform: 'scale(2.4)'
    },
    titleContainer: {
        [theme.breakpoints.down('md')]: {
            fontSize: '10vmin',
            paddingBottom: '7vmin',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        },
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'oswald',
        color: '#5a2a20',
        fontSize: '8vmin',
        marginTop: '2vh',
        paddingBottom: '4vmin',
        WebkitTextStroke: '2px',
        WebkitTextStrokeColor: '#b68f72',
        textShadow: 'black 1px 0 5px'
    },
    body: {
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '5vh'
    },
    cr: {
        width: '35vmin',
        marginLeft: '1vw'
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
            paddingBottom: '3vh',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        },
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#b68f72',
        fontWeight: 'bold',
        fontFamily: 'oswald',
        fontSize: '3vmin',
        textShadow: 'black 1px 0 5px',
        marginBottom: '3%'
    },
    author: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
            paddingBottom: '3vh'
        },
        marginBottom: '5vmin',
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        fontFamily: 'arimo, sans serif',
        fontSize: '2.5vmin'

    },
    authorpic: {
        height: 'auto',
        width: '15vmin',
        borderRadius: '50%',
        marginLeft: '5%'
    },
    excerptHolder: {
        [theme.breakpoints.down('sm')]: {
            width: '90vw'
        },
        width: '50vw',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#5a2a20',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)',
        borderRadius: '10px',
        padding: '2vmin'
    },
    excerpt: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '4.5vmin'
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '1vh',
        color: 'white',
        fontSize: '2vmin',
        fontFamily: 'Roboto Serif, serif',
    },
    exPicContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '2%',
        display: 'flex',
        flexDirection: 'column',
        color: '#5a2a20',
    },
    excerptPic: {
        [theme.breakpoints.down('sm')]: {
            width: '90vw'
        },
        width: '60vh',
        height: 'auto',
        position: 'center'
    },
    excerptPic2: {
        width: '40vh',
        height: 'auto'
    }
}))



const Crossroadsexcerpt = ({ crossroads, setCrossroads }) => {

    useEffect(() => {
        setCrossroads(true)
    }, [])

    const classes = useStyles()

    return <>
        <div className={classes.goBack}>
            <Link1 to="/crossroadshome" style={{ color: 'inherit', textDecoration: 'none' }}>
                <ArrowCircleLeftOutlinedIcon className={classes.backIcon} />
            </Link1> <div className={classes.goBackText}>BACK TO CROSSROADS HOME</div>
        </div>
        <div className={classes.body}>
            <Helmet>
                <title>EXCERPT</title>
                <meta
                    name="description"
                    content="Read an Excerpt from Crossroads"
                />
            </Helmet>
            <div className={classes.titleContainer}>
                EXCERPT
            </div>
            <div className={classes.excerptHolder}>
                <div className={classes.title}>
                    An Early Morning Canoe Trip, A Desperate Mission . . .
                </div>
                <div className={classes.author}>
                    By Hilda Twongyeirwe <img className={classes.authorpic} src={hilda}></img>
                </div>
                <div className={classes.excerpt}>
                    I woke before sunrise, and we journeyed towards Lake Bunyonyi. Sunrise was becoming my motif – to kill me or save me, I thought as I watched day break. We had to walk because no public transport vehicles operated in that area. As daylight flooded the footpath, I hurried behind Dad. He was wearing his checkered coat and gray trousers. His back was straight and strong. His height lent him a bounce that came with each step that he took. I was proud of him. I still am, but in a different way. These days he is frail and weak most of the time. He is 77 as I write this story. His bounce has given way to a gentle stoop.
                    <br></br>
                    <br></br>
                    <div className={classes.exPicContainer}>
                        <img className={classes.excerptPic} src={bunyonyi}></img>
                        <br></br>
                        Lake Bunyoni
                    </div>
                    <br></br>
                </div>
                <div className={classes.excerpt}>
                    After we got into the canoe and the owner rowed away into the middle of the large lake, fear gripped me. We were surrounded by so much water, and the ripples tapped on the sides of the canoe continuously. “These days the lake is not very gentle,” the canoe owner said. “But we shall cross. And if we die, it will be our day.” I did not like the way he talked about death as if it was of no significance. I did not want this to be my day. Like a dangerous snake, the water meandered, its arms disappearing in between hills and emerging from behind other hills. It was as if the water was following us and marking our movements. Though frightening, it was beautiful. In the sunrise, the sky reflected in the lake was broken into shiny rivulets. It felt like a marriage between the water and the sky – like a promise that we were going to be safe in between the two.
                    <br></br>
                    <br></br>
                    But the ripples increased and some water spilled into the canoe. I shivered and pulled the blue sweater I had borrowed from my mother around me. The canoe man told me not to shiver and not to worry about the little water that was escaping with the wind. “It is nothing compared to the water underneath the canoe,” he said. Dad later told me that lake men appease the water by telling it that it is abundant. When the ripples increased, I called out to Dad and told him that we were making a mistake.
                    <br></br>
                    <br></br>
                    “Why?” he asked.
                    <br></br>
                    <br></br>
                    “Because we can drown in this lake for no reason at all,” I replied.
                    <br></br>
                    <br></br>
                    “That is not true my child,” he said. “We have a reason. Your healing is the reason we are on this journey.”
                    <br></br>
                    <br></br>
                    Dad was seated behind me so I do not know the expression that my words brought to his face. But when he responded, I felt something stir my spirit. I was touched by the calm in his voice and the depth in his words. Then I knew that God would not let us drown. My father’s words reached deep inside me, and brought a new me to the surface – a stronger me. They brought out a girl ready to travel and return home whole. Since we were in a canoe, no one saw the tears that escaped my eyes. A serene aura descended on me, and I felt it settle above the canoe. We were safe. Maybe that is when I was healed.
                    <br></br>
                    <br></br>
                    <div className={classes.exPicContainer}>
                        <img className={classes.excerptPic2} src={boatman}></img>
                        <br></br>
                        A boat man ventures out on a calm day.
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Crossroadsexcerpt