import * as React from 'react';
import { Helmet } from 'react-helmet'
import {useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import ophelia from '../pics/Ophelia-New Photo-IMG_3280 (1).jpeg'

const useStyles = makeStyles((theme) => ({
    body: {
        marginTop: '2vmin',
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '5vh'
    },
    titleContainer: {
        [theme.breakpoints.down('md')]: {
            fontSize: '10vmin',
            paddingBottom: '7vmin',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        },
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'oswald',
        color: '#5a2a20',
        fontSize: '8vmin',
        paddingBottom: '4vmin',
        WebkitTextStroke: '2px',
        WebkitTextStrokeColor: '#b68f72',
        textShadow: 'black 1px 0 5px'
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '7vmin',
            paddingBottom: '3vh',
            textShadow: 'black 0px 0 0px',
        },
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#b68f72',
        fontWeight: 'bold',
        fontFamily: 'oswald',
        fontSize: '5vmin',
        textShadow: 'black 1px 0 5px',
        marginBottom: '3%'

    },
    author: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vmin',
            paddingBottom: '1vh'
        },
        display: 'flex',
        alignItems: 'center',
        color: '#b68f72',
        fontFamily: 'arimo, sans serif',
        fontSize: '2.5vmin'
    },
    date:{
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vmin',
            paddingBottom: '1vh'
        },
        
        display: 'flex',
        alignItems: 'center',
        color: '#b68f72',
        fontFamily: 'arimo, sans serif',
        fontSize: '2.5vmin',
        marginBottom: '1vh'
    },
    authorPic: {
        [theme.breakpoints.down('sm')]: {
            
        },
        height: 'auto',
        width: '20vmin',
        borderRadius: '50%',
        marginLeft: '5%'
    },
    blogHolder: {
        [theme.breakpoints.down('sm')]: {
            width: '90vw'
        },
        width: '50vw',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#5a2a20',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)',
        borderRadius: '10px',
        paddingLeft: '2vw',
        paddingRight:'2vw',
    },
    blog: {
        [theme.breakpoints.down('xs')]: {
           fontSize: '4vmin'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            fontSize: '4vmin'
        },
        fontSize: '2vmin',
        marginTop: '2vh',
        width: '100%',
        
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '1vh',
        color: 'white',
        fontSize: '2vmin',
        fontFamily: "Open Sans, sans-serif",
        marginBottom: '2vh'
    },
    blogPicContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '2%',
        display: 'flex',
        flexDirection: 'column',
        color: '#5a2a20',
    },
    postPic:{
        [theme.breakpoints.down('sm')]: {
            width: '50%',
            marginBottom: '3vh',
            marginTop: '2vh'
        },
        [theme.breakpoints.down('sm')]: {
            width: '50%',
            marginBottom: '3vh',
            marginTop: '2vh'
        },
        float: 'right',

        width: '40%',
        marginLeft: '2vw',
        marginBottom: '1vh'
    },
    postBio: {
        fontStyle: 'italic'
    },
    divider: {
        borderBottom: '5px solid white',
        marginBottom: '2vw'
    }

}))

const Blog = ({ crossroads, setCrossroads }) => {

    useEffect(() => {
        setCrossroads(false)
    }, [])

    const classes = useStyles()


    return (
        <>
            <div className={classes.body}>
            <Helmet>
                <title>BLOG</title>
                <meta
                    name="description"
                    content="Read Blog Articles related to Remembering The Future"
                />
            </Helmet>
                <div className={classes.titleContainer}>
                    BLOG
                </div>
                <div className={classes.blogHolder}>

                    <div className={classes.post}>
                        <div className={classes.title}>
                            Under Attack
                        </div>
                        <div className={classes.authorandDate}>
                            <div className={classes.author}>By Ophelia Kemigisha </div>
                            <div className={classes.date}>5/5/23</div>
                        </div>
                        <div className={classes.blog}>

                                <img className={classes.postPic} src={ophelia}></img>
                                When I was 13 years old, my brother slapped me hard across the face. I was washing dishes
                                in the kitchen, which used to be a hangout spot for everybody. Like many Ugandan
                                households, it was home to a diverse collection of people. I lived with my three sisters, and
                                cousins and other relatives stayed with us at different times. My half-brother was a late-
                                comer, having arrived in our family after spending most of his early years with his mother.
                                When he hit me, I remember bursting into tears and leaving the kitchen, too startled to hit
                                him back. I cannot recall what the spat was about, and we now share a close friendship.
                                <br></br>
                                <br></br>
                                My sisters and I often fought when we were growing up – for the TV remote, to ride
                                shotgun, and even for sport. After slapping me, he was spanked for a crime we’d been
                                getting away with – apparently, he was not allowed the privilege of sibling rivalry. What
                                stuck with me was the reason for his punishment: During the firm disciplining, my father
                                asked him repeatedly what he had been doing in the kitchen in the first place.
                                <br></br>
                                <br></br>
                                Growing up in a predominantly female household and then attending an all-girls boarding
                                secondary school, did not prepare me for “real life.” For a long time, the distinction society
                                makes between men and women was not obvious to me. I had seen girls play football, excel
                                in class, and take up leadership positions.
                                <br></br>
                                <br></br>
                                I grew up in the late 1990’s and early 2000s, when mainstream media were filled with
                                stories of empowered women. The tough-as-nails Justice Julia Sebutinde, who was recently
                                appointed to the International Court of Justice, was leading a highly publicised probe into
                                corruption scandals, which led to various changes in public offices in Uganda. She was
                                described as the petite fiery judge. It was said she could make grown men shake in their
                                trousers. She made many shed tears. We also had the first female vice president in Africa,
                                Dr Specioza Wandira Kazibwe, for nine years. We had this female Member of Parliament,
                                Hon Miria Matembe, who proposed that rapists be castrated. It raised hell in Uganda’s
                                public! Many other women were starting to rise to the top in different spheres.
                                <br></br>
                                <br></br>
                                Still, I was hoodwinked to believe that men were superior. Men were the “heads of the
                                family,” the billionaires, the “natural” leaders. This story is sold in mainstream media, in
                                movies and soaps, in church, even in the school’s curriculum. We still were told that men
                                had certain roles and women had others. Men provided for their families and worked;
                                women cooked and cleaned. Women could study and work, but were often reminded that
                                they were not men, so they had to go home and be women. It was common to praise
                                women for how much work they did for their husbands and children, but to give them no
                                recognition for what they did outside the home.
                                <br></br>
                                <br></br>
                                Society has evolved in many different ways since. The average Ugandan has gone through
                                some formal education, owns a mobile phone, watches television, and probably has a
                                theory about why Donald Trump was elected. People are willing to embrace change in many
                                areas of their life: they can get new phones, change their style of dressing, even try out a
                                new beer. Women, especially the single ones, are among the big land owners in and around
                                Kampala. But the ration is still low.
                                <br></br>
                                <br></br>
                                But Ugandans still refer to women with a nostalgic shake of their heads and the word
                                “nowadays.” “You know women of nowadays: they want to be men,” the saying goes.
                                <br></br>
                                <br></br>
                                Yet in reality, many women “nowadays” are fighting back against the “culture” – especially
                                when they realise that they have been given the short end of the stick. In many Ugandan
                                communities, women are pushed to the margins under the pretext they have to observe
                                traditional gender roles. One of the areas that remains heavily influenced by culture is
                                weddings and marriage. Even amongst “middle class” Ugandans, there is a strong
                                attachment to cultural rites surrounding weddings. During bridal showers, for instance,
                                ssengas dispense marriage and sex advice centred on pleasing the man (this function used
                                to be served by paternal aunts of the bride, but the role has today been turned round for
                                economic value and is done by hired professionals. Among my people, the Banyankore, it is
                                men from the potential groom and potential bride’s family who are almost exclusively
                                involved in negotiating enjugano, loosely translated as “bride price”. This haggling over the
                                value of brides as if they were cows in the market has become especially odd in this age
                                where money takes precedence over all. This is compounded by the absence of women in
                                the process, so that it all strikes me as deeply patriarchal.
                                <br></br>
                                <br></br>
                                Today, women are increasingly sharing space with men in ways that the society previously
                                did not allow; they can be business partners, work colleagues, friends and “friends with
                                benefits.” Yet as the famous religious instruction by St. Paul who required women to submit
                                only to their husbands is quoted and emphasised at every wedding, men in all these spaces
                                expect this submission. Many Ugandan men are very comfortable living by a double
                                standard. Yes, a woman must work outside the home and contribute, but she must also
                                carry out the bulk of house work – and care for the children and older relatives. We are told
                                we must raise girls to be assertive, but they must also obey and “respect” men because men
                                are still in charge.
                                <br></br>
                                <br></br>
                                In urban centres, some women are now at the helm of organisations, literally signing men’s
                                pay cheques. This shows that women are just as able as men. When the boys beat you in
                                class today and you beat them tomorrow, you get better performance reviews at work
                                today and they get better reviews following day, then you start to think: Maybe men are not
                                special after all.” It then becomes difficult to watch your husband watch TV as you break
                                your back with household chores and care-work when you cross the threshold after
                                knowing you can outsmart men just the same way they can outsmart women.
                                <br></br>
                                <br></br>
                                Despite the ostensible advances women have made, many men – and women – still have a
                                hard time getting accustomed to women occupying spaces outside of the home. The notion
                                of having a female president is laughable to most, even though Speciosa Wandira Kazibwe
                                once served as Vice President. Some members of the elites grumble that affirmative action
                                is “going too far” and is putting boys and men at a disadvantage. In 1990, the government
                                decided to give an extra 1.5 points in admission scores to all female students joining public
                                universities, and the number of female graduates started to increase. At Makerere, the

                                nation’s largest university, the share of females graduated surpassed males for the first time
                                in 2010 and reached around 55% overall in some years after that. But the female rate
                                dropped to a meagre 0.6 percentage points in 2019. Meanwhile, in Parliament, female
                                representation is below 35%, even when seats are set aside for women in every
                                constituency. This, apparently, is Uganda’s definition of going too far.
                                <br></br>
                                <br></br>
                                Moreover, the crop of empowered women makes up only a small, privileged group. The
                                majority is poorer women who have access to resources only via male relatives or husbands
                                and continue to suffer. Burdened with unpaid care work like having almost the sole
                                responsibility for raising children and taking care of the elderly and the sick, they still work
                                on land they do not own. Sometimes they do not even have ownership of what they grow
                                on this land. They also continue to suffer domestic violence at disproportionate rates.
                                Unfortunately, the success of a few visible women, especially those in positions of authority
                                has not translated to gender equality all over the country.
                                <br></br>
                                <br></br>
                                A whole new world has opened up for women, though: We can go to school, occupy
                                legislative office, open businesses, own land and travel the world – all without the approval
                                of a man. It is okay for women to be found everywhere: at the helm of the city council, in a
                                bar at 3 am, in the corner office, on social media yelling “Men are trash!” and organising a
                                Women’s March.
                                <br></br>
                                <br></br>
                                On social media, interactions between men and women have become more and more
                                fraught and combative. Regardless of what a woman posts, she is likely to receive unseemly
                                comments. A cursory glance through the comments on a female celebrity’s photo posted on
                                Twitter includes an unflattering picture posted as if to discredit her beauty, jokes about her
                                having to announce her new clothes, references to her genitalia, and one lone, inexplicable
                                “B**ch.”
                                <br></br>
                                <br></br>
                                Women who identify as feminists and use the internet for activism report higher levels of
                                harassment and online abuse. Insults usually have to do with us being emotional, ugly,
                                unlovable, and unlikely to find men to marry because we are ugly and unlovable.
                                Sometimes, the attacks seem organised, with trolls swarming online like bees. They go
                                beyond insults; trolls sometimes dish out rape and murder threats. A close friend who
                                started posting openly feminist posts on her Facebook page received numerous comments
                                that she is sex- deprived and wishes to be sexually assaulted. A number of men started
                                commenting on her posts, exchanging jokes about this possibility; one of them bizarrely,
                                targeting her nose. Trolls might be easy to dismiss when they are reported in the news as
                                manufactured in a lab to influence various elections, but it is not that simple when they are
                                friends of friends, men who are known to someone you know, who could very easily find
                                you if they tried.
                                <br></br>
                                <br></br>
                                In pop culture, it is perfectly acceptable for men to sing songs that claim women as
                                “property” and for adverts to portray street harassment presented as if it were a normal
                                way for a man to approach a woman. If a woman points out that such stereotypes are
                                harmful to women, she is accused of taking everything too seriously. Yet, this sense of
                                entitlement to the bodies of women really is harmful. I learnt how to walk further away
                                from men in downtown Kampala when I was still a teenager. My friends and I are constantly
                                afraid, or at least wary, that a man may interrupt a good day with a form of violence, from
                                catcalling or groping, to physical or sexual assault.
                                <br></br>
                                <br></br>
                                On the other hand, men mostly express annoyance at being rejected by women, whether
                                that takes the form of refusing a hug or blocking them on social media. Of course, many
                                men in Uganda face some form of oppression depending on their class, sexual orientation or
                                ethnic origin. This, however, does not necessarily translate into an empathetic view of how
                                women navigate a male-dominated world and a commitment to refrain from harming the
                                women they encounter.
                                <br></br>
                                <br></br>
                                In my interactions with men, some of them have expressed dissatisfaction with the place
                                society assigns them. Yet, they are unwilling to go against the grain and give up the power
                                that comes with the societal pressure to be “man enough”. We have all received and
                                absorbed the same messages about masculinity and femininity. Unlearning these narratives
                                can be a painful process, and less attractive to those who mostly benefit from keeping
                                things as they are.
                                <br></br>
                                <br></br>
                                I am an endless optimist. Despite all the problems I see, and how dysfunctional relationships
                                between men and women often seem, I like to think that more and more people are
                                reimagining life outside of the known social script. That means that we can create a new
                                society where men and women can achieve their full potential. Society can relieve men of
                                the burden to dominate and women of the burden to submit, regardless, and allow them to
                                exercise their full humanity. Society can allow women to enjoy full bodily autonomy and
                                agency and live more fulfilling lives. People often say women and men misunderstand each
                                other, but I think dismantling systems that oppress one group will clear up those
                                communication channels.
                                <br></br>
                                <br></br>
                                I know my brother will never hit a woman because he was taught that lesson at an
                                impressionable age with my father’s red slipper. Even better, I know that as we come to see
                                so many women defining themselves without reference to gender roles, he can fathom
                                women as full human beings outside of their “roles” at home. And today, my brother is not
                                afraid to go to the kitchen: That is the progress that will create a better and free society for
                                women and men.
                                <br></br>
                                <br></br>
                                <span className={classes.postBio}>
                                    Ophelia Kemigisha is a feminist activist, human rights lawyer, and writer. She is interested in
                                    infusing radical politics into legal structures in order to fight patriarchal violence and
                                    oppression. She lives in Kampala, Uganda where she reads, dances, and tweets for the
                                    revolution.
                                    <br></br>
                                    <br></br>
                                    “Remembering the Future” has several chapters that address gender relations and roles.
                                    Edna Namara’s “The Bride’s Farewell Song” describes the recollections of a grandmother on
                                    weddings and marriages in the hill country of Rukiga in Western Uganda. Linda Orando’s
                                    “Goria Ubuntu” shares the reflections of her grandfather, a family patriarch, on family and
                                    the roles of men and women in the family’s compound in Asinge in Eastern Uganda. And
                                    Wobusobozi Amooti Kangere shatters stereotypes in “A Tale of Two Matriarchs,” the story of
                                    powerful matriarchs who led his family and community western Uganda’s Ibanda district.

                                </span>
                            </div>
                    </div>

                    <div className={classes.divider}>

                    </div>

                </div>

            </div>
        </>
    );


}

export default Blog;

