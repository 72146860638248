import * as React from 'react';
import { Helmet } from 'react-helmet'
import { useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import { ClassNames } from '@emotion/react';

const useStyles = makeStyles((theme) => ({
    body: {
        marginTop: '2vmin',
        minHeight: '100vh',
        height: '100%',
        minHeight:'100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    titleContainer: {
        [theme.breakpoints.down('md')]:{
            fontSize: '10vmin',
            paddingBottom: '7vmin',
            textShadow:'black 0px 0 0px',
            WebkitTextStroke: '0px',
            },
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems:'center',
        fontFamily: 'oswald',
        color: '#5a2a20',
        fontSize: '8vmin',
        paddingBottom: '4vmin',
        WebkitTextStroke: '2px',
        WebkitTextStrokeColor: '#b68f72',
        textShadow:'black 1px 0 5px'
       },
       reviewContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '90vw'
        },
        width: '50vw',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#5a2a20',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)',
        borderRadius: '10px',
        padding: '2vmin'
    },
    reviewBox: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '4.5vmin',
            marginBottom: '5vh'
        },
        marginBottom: '2vw',
        color: 'white',
        fontSize: '2vmin',
        fontFamily: "Open Sans, sans-serif",
        borderBottom: '3px solid white'
    },
    reviewContent: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '4.5vmin',
            marginBottom: '5vh'
        },
        marginBottom: '2vw'
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '4.5vmin',
            marginBottom: '5vh'
        },
        color: '#B49B57',
        fontWeight: '500',
        fontSize: '2vmin',
        fontFamily: "Open Sans, sans-serif",
        // borderBottom: '3px solid #b68f72',
        width: '100%',
        marginBottom: '2vw'
    },
    reviewBox2: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '4.5vmin',
            marginBottom: '5vh'
        },
        marginBottom: '2vw',
        color: 'white',
        fontSize: '2vmin',
        fontFamily: "Open Sans, sans-serif",
        // fontWeight: '500',
        // fontFamily: 'Roboto Serif, serif',
    },
    link: {
        color: '#B49B57', 
        textDecoration: 'none', 
        borderBottom: '1px solid blue',
        "&:hover": {
            cursor: 'pointer'
          }
    }
}))

const Reviews = ({crossroads, setCrossroads}) => {

    useEffect(() => {
        setCrossroads(false)
    }, [])

    const classes = useStyles()

    return (
        <div className={classes.body}>
            <Helmet>
                <title>REVIEWS</title>
                <meta
                    name="description"
                    content="Read Reviews of Remembering The Future"
                />
            </Helmet>
            <div className={classes.titleContainer}>
                REVIEWS
            </div>
        
            <div className={classes.reviewContainer}>
                <div className={classes.reviewBox}>
                    <div className={classes.reviewContent}>"To me, it is the most outstanding book that Uganda has yet mothered. [...] Remembering the Future is an instant brain teaser." (<a style={{ color: '#B49B57', textDecoration: 'none', borderBottom: '1px solid blue' }} href="https://www.monitor.co.ug/uganda/oped/commentary/remembering-the-future-by-12-ugandans-4265396">Prof Timothy Wangusa, monitor.co.ug</a>)</div>
                </div>
                    
                        <div className={classes.container}>
                            <div className={classes.option1}>
                                <div className={classes.title}>
                                Remembering the Future was the "Book of the Day" on the Online Book Club on April 4, 2024. Here are reviews by club members.
                                </div>
                                <div className={classes.reviewBox2}>
                                "Through personal and real-life stories, the authors take the readers on a trip down memory lane,
                                reminding them of life in pre-colonial times, creating a contrast against the present situation, and
                                trying to offer a way out in the future.  It provided me with incredible insight into Ugandan society. I
                                got to appreciate their customs and cultural values. I recommend this book to people who enjoy
                                reading about the origins and history of societies."                                
                                <a className={classes.link} href='https://forums.onlinebookclub.org/viewtopic.php?t=362314'> (Abraham Ozo)</a>
                                </div>
                                <div className={classes.reviewBox2}>
                                "A thought-provoking exploration [that] delves into the complex interrelationship among language,
                                traditions, faith, and heritage shaping both the past and the future.  I adore this book for its exquisite
                                portrayal of Ugandan culture…. The seamless blending of voices among the writers creates a
                                captivating narrative that honors the resilience and evolution of Ugandan traditions."                         
                                <a className={classes.link} href='https://forums.onlinebookclub.org/viewtopic.php?t=452816'> (David Ishola)</a>
                                </div>
                                <div className={classes.reviewBox2}>
                                "A captivating anthology. examines the juxtaposition between evolving desires and entrenched
                                beliefs within the rapidly changing Ugandan landscape… By drawing on firsthand experiences and
                                insightful dialogues with elders, the writers offer a rich and multifaceted portrayal of Uganda&#39;s
                                history and cultural dynamics…. Each character offers a unique perspective, contributing to the rich
                                tapestry of narratives presented in the book… Conte&#39;s captivating storytelling style ensures that
                                readers are fully immersed in the experiences of the characters, making the book both engaging and
                                enlightening.”                
                                <a className={classes.link} href='https://forums.onlinebookclub.org/viewtopic.php?t=453979'> (Lumie Blazrr)</a>
                                </div>
                                <div className={classes.reviewBox2}>
                                "A standout literary work. Through engaging narratives, the authors illuminate the diverse issues,
                                challenges, and values prevalent across Africa, offering insights into the methods of safeguarding
                                and upholding cultural heritage amidst global societal transformations… I found myself drawn to the
                                authentic portrayal and thorough exploration of African culture and traditions. This book earns a
                                perfect rating for its invaluable contribution to cultural research and exploration. I would recommend
                                this book to readers eager to immerse themselves in a poignant and insightful journey through
                                African culture and traditions."               
                                <a className={classes.link} href='https://forums.onlinebookclub.org/viewtopic.php?t=454000'> (Clever Ahonsi)</a>
                                </div>
                                <div className={classes.reviewBox2}>
                                "A tapestry of voices. The book’s insightful examination of Uganda&#39;s shift from a communal to a
                                monetary economy [is] eye-opening. The stories of individuals grappling with these changes make
                                the economic concepts personal and tangible. It&#39;s not just about numbers and markets; it&#39;s about the
                                heartache of traditions fading and the challenge of adapting to new realities. This book has
                                deepened my understanding of the delicate balance between progress and preservation and the
                                resilience of communities that are navigating this seismic shift in their economic
                                landscape."          
                                <a className={classes.link} href='https://forums.onlinebookclub.org/viewtopic.php?t=451351'> (Boluwatife Blessing)</a>
                                </div>
                                <div className={classes.reviewBox2}>
                                "A contemplative anthology. It brilliantly explores the mystical elements of Ugandan culture, offering
                                captivating insights into spirituality, superstition, and storytelling traditions. Stephen Ssenkaaba&#39;s
                                essay, in particular, provides a fascinating glimpse into how ancient folklore continues to influence
                                modern society, especially through Uganda&#39;s burgeoning film industry. Additionally, the book delves
                                into important societal issues, such as the status of women and the legacy of colonialism, providing
                                valuable historical and contemporary perspectives that make me give it a perfect rating. I highly
                                recommend it to anyone interested in gaining a deeper understanding of Ugandan culture,
                                spirituality, and societal dynamics, as well as to those who simply enjoy engaging and thought-
                                provoking reads."     
                                <a className={classes.link} href='https://forums.onlinebookclub.org/viewtopic.php?t=451304'> (Oghenemaro Odhibi)</a>
                                </div>
                                <div className={classes.reviewBox2}>
                                "A beacon of hope.  This anthology stands out for challenging conventional notions of progress and
                                advocating for a reevaluation that prioritizes human values and community resilience over
                                materialistic and individualistic gains. Its exploration of linguistic evolution and cultural traditions
                                deepens the narrative, providing insights into the nuanced view of post-colonial African identity with
                                authenticity and clarity. The book&#39;s appeal transcends geographical and cultural boundaries, offering
                                readers worldwide a window into Ugandan life through its rich storytelling."
                                <a className={classes.link} href='https://forums.onlinebookclub.org/viewtopic.php?t=452927'> (Irorere Osarenren Godswill)</a>
                                </div>
                                <div className={classes.reviewBox2}>
                                "A poignant examination of modern societal issues in Uganda through the prism of its pre-colonial
                                heritage. Crafted with skillful storytelling and deep cultural insights, the book delves into the
                                complexities of African identity in the post-colonial era, providing readers with a captivating journey
                                through time. The masterful portrayal of vibrant cultures, traditions, and rituals, along with the
                                opportunity for readers to learn and appreciate Ugandan customs, makes this book an invaluable
                                resource for cultural understanding and appreciation. Its thought-provoking narratives encourage
                                reflection on the enduring impact of colonialism while celebrating the resilience of African
                                heritage."
                                <a className={classes.link}> (Victor13, OnlineBookClub.org)</a>
                                </div>
                                <div className={classes.reviewBox2}>
                                "A profound narrative. Through evocative storytelling and insightful analysis, the author seamlessly
                                blends tradition with present realities, presenting a compelling tale of resilience. Each chapter takes
                                readers on a captivating journey across Uganda&#39;s diverse landscapes, from Kampala&#39;s lively streets
                                to Lake Victoria&#39;s tranquil shores, illuminating the complexities of societal change. What
                                distinguishes this book is its deep respect for tradition alongside a forward-thinking outlook, inviting
                                readers to ponder identity and community in a rapidly shifting world. . .  The book&#39;s exhaustive
                                examination of the intersection between tradition and modernity ensures its relevance not only to
                                Ugandans but also to a global audience, making it a must-read for anyone interested in
                                understanding cultural evolution in today&#39;s rapidly changing world."
                                <a className={classes.link} href='https://forums.onlinebookclub.org/viewtopic.php?t=455540'> (Onoiton Okojie)</a>
                                </div>
                                <div className={classes.reviewBox2}>
                                "[A] profound exploration of identity, delving into the powerful influence of language, customs, faith,
                                and heritage on shaping our past, present, and future. . . the book&#39;s conclusion, with two essays
                                depicting a semblance of cultural harmony, further adds to its appeal. Stephen Ssenkaaba&#39;s
                                &quot;Wakaliwood: Uganda’s Answer to Hollywood&quot; provides an intriguing glimpse into the modern
                                portrayal of spirits and demons from Ugandan folklore in cinema, while Flavia Nassaka’s &quot;A Healing
                                Tradition&quot; offers a touching narrative of how the legacy of a traditional healer continues through her
                                son&#39;s work as a psychiatrist and educator."
                                <a className={classes.link} href='https://forums.onlinebookclub.org/viewtopic.php?t=455604'> (Obo Divine Goodnews)</a>
                                </div>
                                <div className={classes.reviewBox2}>
                                "A journey to rediscover cultural roots on themes such as gender relations, religion, leadership,
                                modern beliefs, mental health, and power. . . Unlike single-story narratives, this anthology offers
                                readers a diverse range of perspectives and narratives. [Its] multifaceted approach to storytelling left
                                me feeling enriched and knowledgeable about Uganda&#39;s rich cultural heritage. . .  One standout story
                                for me was &quot;A Bride&#39;s Farewell Song&quot; by Edna Namara, which provided a fascinating glimpse into
                                courtship and marriage practices of the past, contrasting sharply with modern customs."
                                <a className={classes.link} href='https://forums.onlinebookclub.org/viewtopic.php?t=453973'> (Maureen Opute)</a>
                                </div>
                                <div className={classes.reviewBox2}>
                                "A must-read for those interested in historical writings and cultural exploration. Through skillful
                                storytelling, evocative language, and a commitment to preserving African values, the book not only
                                educates but also instills a sense of belonging and pride in cultural identity. Ultimately, this book
                                transcends the label of a mere book, becoming a transformative experience that inspires me to
                                embrace diversity and cherish the richness of our collective human heritage."
                                <a className={classes.link} href='https://forums.onlinebookclub.org/viewtopic.php?t=452915'> (Emmanuel Oziegbe)</a>
                                </div>
                                <div className={classes.reviewBox2}>
                                "A captivating collaboration that delves deeply into the essence of Ugandan identity, exploring the
                                profound influence of language, customs, faith, and heritage across time. There&#39;s a nostalgic charm
                                to it, as even though the authors are Ugandan, the themes resonate deeply with the broader African
                                community across diverse countries. Additionally, the book skillfully delves into how Ugandan
                                cultures have adapted amidst global influences, skillfully balancing the preservation of cherished
                                traditions with the embrace of progress."
                                <a className={classes.link} href='https://forums.onlinebookclub.org/viewtopic.php?t=452925'> (Peter Ubani)</a>
                                </div>
                                <div className={classes.reviewBox2}>
                                "A poignant examination of gender dynamics within contemporary Ugandan society, weaving
                                personal anecdotes with broader societal reflections. Conte adeptly navigates the enduring influence
                                of patriarchy despite advancements for women, capturing the urgency of everyday confrontations
                                and societal struggles observed in social media debates and domestic conflicts. Transporting
                                readers to an era of communal living and shared responsibilities, Conte prompts contemplation on
                                the essence of progress and the impacts of societal transformation through his juxtaposition of
                                traditional customs with modern challenges."
                                <a className={classes.link} href='https://forums.onlinebookclub.org/viewtopic.php?t=454004'> (Confidence Chinenye)</a>
                                </div>
                            </div>
                        </div>
                        
            </div>
            
        </div>
    )
}

export default Reviews;