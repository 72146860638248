import * as React from 'react';
import { Helmet } from 'react-helmet'
import {makeStyles} from '@material-ui/core/styles'
import {useState, useEffect} from 'react'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { Link as Link1 } from 'react-router-dom'


const Culture = ({crossroads, setCrossroads}) => {



    const useStyles = makeStyles((theme) =>({
        body: {
            height: '100%',
            minHeight: '100vh',
            width: '100vw',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
        },
        videoWrap: {
            [theme.breakpoints.down('md')]: {
                width: '90vw'
            },
            width: '80%',
            height: '100%',
            marginTop: '3vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#5a2a20',
            boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)',
            borderRadius: '10px',
            padding: '1vmin'
        },
        titleContainer: {
            [theme.breakpoints.down('md')]:{
                fontSize: '5vw',
                paddingLeft: '2vmin',          
                WebkitTextStroke: '0px',
                textShadow:'black 0px 0 0px'
                },
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems:'center',
                fontFamily: 'oswald',
                color: '#b68f72',
                fontSize: '3vw',
                marginTop: '1vh',
                marginBottom: '1vh',
           },
        description: {
            [theme.breakpoints.down('md')]: {
                fontSize: '4vw'
                },
            display: 'flex',
            justifyContent: 'center',
            width: '100vw',
            paddingTop: '2vh',
            paddingBottom: '2vh',
            fontSize:'1.5vw',
            fontStyle: 'italic',
            color: 'white'
        },
        videoContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '100vw',
            marginBottom: '2vh'
        },
        responsiveiFrame: {
            [theme.breakpoints.down('md')]: {
            width: '90vw',
            height: '54vw'
            },
         width: '55vw',
         height: '33vw'
        }
    }))

    useEffect(() => {
        setCrossroads(false)
    }, [])

    const classes = useStyles()

    return <>
    <div className={classes.body}>
        <Helmet>
            <title>UGANDA CULTURAL SUMMIT</title>
            <meta
                    name="description"
                    content="Watch a video by Linda Orando"
                />
                <meta
                    name="keywords"
                    content="Uganda, Patriarchy, Culture"
                />
        </Helmet>
        <div className={classes.videoWrap}>
                <div className={classes.titleContainer}>
                    UGANDA CULTURAL SUMMIT 2024
                </div>
                
                <div className={classes.videoContainer}>
                <iframe className={classes.responsiveiFrame} src="https://www.youtube.com/embed/Cpl7prZcTig?si=RwL5JEjOIDC5jt9M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
        </div>
    </div>
    </>
    
}

export default Culture

"https://www.youtube.com/embed/OPmjxIUkDlM"