import * as React from 'react';
import { Helmet } from 'react-helmet'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ClassNames } from '@emotion/react';

import aliker from '../pics/Aliker.png'


const useStyles = makeStyles((theme) => ({
    body: {
        marginTop: '2vmin',
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '5vh'
    },
    titleContainer: {
        [theme.breakpoints.down('md')]: {
            fontSize: '10vmin',
            paddingBottom: '7vmin',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        },
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'oswald',
        color: '#5a2a20',
        fontSize: '8vmin',
        paddingBottom: '4vmin',
        WebkitTextStroke: '2px',
        WebkitTextStrokeColor: '#b68f72',
        textShadow: 'black 1px 0 5px'
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
            paddingBottom: '3vh',
            textShadow: 'black 0px 0 0px',
        },
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#b68f72',
        fontWeight: 'bold',
        fontFamily: 'oswald',
        fontSize: '3vmin',
        textShadow: 'black 1px 0 5px',
        marginBottom: '3%'

    },
    author: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
            paddingBottom: '3vh'
        },
        marginBottom: '5vmin',
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        fontFamily: 'arimo, sans serif',
        fontSize: '2.5vmin'

    },
    authorpic: {
        height: 'auto',
        width: '15vmin',
        borderRadius: '50%',
        marginLeft: '5%'
    },
    excerptHolder: {
        [theme.breakpoints.down('sm')]: {
            width: '90vw'
        },
        width: '50vw',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#5a2a20',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)',
        borderRadius: '10px',
        padding: '2vmin'
    },
    excerpt: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '4.5vmin'
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '1vh',
        color: 'white',
        fontSize: '2vmin',
        fontFamily: "Open Sans, sans-serif",
    },
    exPicContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '2%',
        display: 'flex',
        flexDirection: 'column',
        color: '#5a2a20',
    },
    excerptPic: {
        [theme.breakpoints.down('sm')]: {
            width: '90vw'
        },
        width: '60vh',
        height: 'auto',
        position: 'center'
    },
    excerptPic2: {
        width: '40vh',
        height: 'auto'
    }
}))

const Excerpt = ({ crossroads, setCrossroads }) => {

    useEffect(() => {
        setCrossroads(false)
    }, [])

    const classes = useStyles()


    return (
        <>
            <div className={classes.body}>
                <Helmet>
                    <title>EXCERPT</title>
                    <meta
                    name="description"
                    content="Read an Excerpt from Remembering The Future"
                />
                </Helmet>
                <div className={classes.titleContainer}>
                    EXCERPT
                </div>
                <div className={classes.excerptHolder}>
                    <div className={classes.title}>
                        An Unexpected Encounter Triggers Powerful Memories
                    </div>
                    <div className={classes.author}>
                        By Aliker P'Ocitti <img className={classes.authorpic} src={aliker}></img>
                    </div>
                    <div className={classes.excerpt}>
                        In 2008, my brother Akena and I joined the largest crowd to gather on Kaunda parade grounds
                        in Gulu since Pope John Paul II visited in 1993. But this gathering was different than the earlier
                        ones. We were waiting to hear from delegates who were representing the internationally
                        notorious warlord, Joseph Kony, at peace talks aimed at ending a war that had killed, maimed,
                        terrorized and uprooted more than a million northern Ugandans for two decades.
                        <br></br>
                        <br></br>
                        The crowd was packed so tightly we could hardly move. As we waited, I imagined the kinds of
                        thoughts that were running through people’s minds. Some no doubt thought about the more
                        than 100,000 Acholi people who had been killed, or the tens of thousands of children who had
                        been kidnapped to serve as soldiers, porters and sex slaves for Kony’s band of soldiers, known
                        as the Lord’s Resistance Army. Many had experienced or knew victims of terrorism and brutal
                        torture. Even more – 1.5 million people – had memories of being compelled to crowd into,
                        squalid camps to escape rebel raids that routinely shattered the peace at night.
                        <br></br>
                        <br></br>
                        Suddenly, I was roused from my thoughts. Akena grabbed my hand in a grip so tight my fingers
                        quickly became numb. He was staring intensely toward a pavilion that had been erected at the
                        center of the parade grounds. I followed his gaze, and there, to my amazement, stood our
                        brother, Opoka, among representatives of the feared rebel army. He was smartly dressed like
                        an African American Marine in full combat fatigues and desert-trooper boots. Dark shades were
                        clipped to his chest, and his military cap was pulled low, blocking a clear view of his eyes. At his
                        waist, he carried a big Revolvo pistol in its holster. He stood with open legs and a straight,
                        inscrutable face, scanning the tightly packed horde around him as if on the look-out for a sniper
                        attack. I was speechless. I had not seen him in a decade – ten years of worry and uncertainty
                        about what had become of him.
                        <br></br>
                        <br></br>
                        Though he looked out on a sea of faces, our eyes somehow met. He smiled for a moment, but
                        then he tensed. Then I saw a sight I will never forget. Tears began to run down his face. I could
                        feel my own squinting eyes start to flood too. There was a murmur among people around us
                        who witnessed but did not understand this intensely private moment between two men
                        surrounded by a throng of strangers.
                        <br></br>
                        <br></br>
                        The spell was broken after a few seconds. Opoka nodded to us and turned away. I was so dazed
                        I remember little else about the event, except that when it ended, Opoka and the other rebels
                        immediately drove away in six green Land Cruisers with military number plates and tinted
                        windscreens. We had no chance to speak.
                        <br></br>
                        <br></br>
                        The brief encounter sent a wave of thoughts and emotions flooding over Akena and me. Akena
                        recalled a night two decades earlier, when our cousin, Oruka, had come to our home to talk
                        with our dad, the closest person he had to a father since his own parents had died years earlier.
                        Oruka was a senior officer in the Uganda National Liberation Army (UNLA), which had rebelled
                        when Yoweri Museveni claimed to represent the new government of Uganda after President
                        Tito Okello fell in 1986. The rebellion quickly collapsed, and Oruka returned to Gulu as part of a
                        team of rebels granted safe passage to negotiate a peace agreement. After his visit with my
                        father, he left to rejoin his fellow rebels, who were staying in a military barracks during the
                        talks. A little while later, gun shots rang out in the night. In the morning our worst fears were
                        realized. Oruka’s body was found a meter away from the fence of the barracks. His head was
                        facing the fence, and his arms and legs were stretched as if he was running for his dear life. His
                        back was riddled with bullets and soaked in blood. The news broke our hearts. It felt as if our
                        lost brother had come the previous night to bid my father goodbye. The murder was never
                        solved, but Akena was convinced Oruka had been shot by government soldiers.
                        <br></br>
                        <br></br>
                        As we walked away from the parade grounds, he asked me whether I thought history would
                        repeat itself. “Oruka trusted the government during the peace process, but his body was
                        reportedly found around the fence of the barracks,” he said. “Now, Opoka is a member of
                        another military peace delegation, and he has to trust the same people his brother trusted.”
                        <br></br>
                        <br></br>
                        My mind wandered even farther back – to our childhood.
                    </div>
                </div>
            </div>
        </>
    );


}

export default Excerpt;