import * as React from 'react';
import { Helmet } from 'react-helmet'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ClassNames } from '@emotion/react';

import one from '../pics/IMG_9937.jpg'
import
smilingman
    from '../pics/Uganda4-Introduction0060.JPG'
import three from '../pics/Wasswa 2.jpg'
import four from '../pics/_DSC0744 (5).jpg'


const useStyles = makeStyles((theme) => ({

    body: {
        marginTop: '2vmin',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    titleContainer: {
        [theme.breakpoints.down('md')]: {
            fontSize: '10vmin',
            paddingBottom: '7vmin',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        },
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'oswald',
        color: '#5a2a20',
        fontSize: '8vmin',
        paddingBottom: '4vmin',
        WebkitTextStroke: '2px',
        WebkitTextStrokeColor: '#b68f72',
        textShadow: 'black 2px 0 5px'
    },
    rtf: {
        width: '40vmin',
        marginLeft: '2vw'
    },
    mainContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '95vw'
        },
        width: '75vw',
        backgroundColor: '#5a2a20',
        padding: '2vh',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)',
        borderRadius: '10px',
        marginBottom: '5vh'
    },
    chapContainer: {
        [theme.breakpoints.down('lg')]: {
            alignItems: 'start'
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        width: '70vw',
        marginBottom: '7vh',
        marginTop: '5vh'
    },
    reverseChapContainer: {
        [theme.breakpoints.down('lg')]: {
            alignItems: 'start'
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100%'
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        width: '70vw',
        marginBottom: '7vh',
    },
    chapList: {
        [theme.breakpoints.down('md')]: {
            marginBottom: '7vh'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        // marginBottom:'7vh',
        width: '70vmin',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

    },
    chapNum: {
        color: '#b68f72',
        fontSize: '4vmin',
        marginRight: '2vw'
    },
    singleChap: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
            paddingBottom: '7vmin'
        },
        color: 'white',
        fontFamily: 'Open Sans, sans-serif',
        fontSize: '2vmin',
        display: 'flex',
        padding: '2%'
    },
    chapTitle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        },
        color: '#b68f72',
        fontWeight: 'bold',
        fontFamily: 'oswald',
        fontSize: '2.5vmin',
        textShadow: 'black 2px 0 5px'
    },
    bannerContainer: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '0'
        },
        display: 'flex',
        justifyContent: 'center',
        marginLeft: '7vh'
    },
    reverseBannerContainer: {
        [theme.breakpoints.down('md')]: {
            marginRight: '0'
        },
        display: 'flex',
        justifyContent: 'center',
        marginRight: '7vh'
    },
    groupImg: {
        [theme.breakpoints.down('sm')]: {
            width:'80%'
        },
        width: '40vmin'
    },
}))


const Chapters = ({ crossroads, setCrossroads }) => {

    useEffect(() => {
        setCrossroads(false)
    }, [])

    const classes = useStyles();

    return (

        <div className={classes.body}>
            <Helmet>
                <title>CHAPTERS</title>
                <meta
                    name="description"
                    content="Take a Peek at the chapters of Remember The Future"
                />
                <meta
                    name="keywords"
                    content="Culture, African Culture, African Stories, Ugandan Stories"
                />
            </Helmet>
            <div className={classes.titleContainer}>
                CHAPTERS
            </div>
            <div className={classes.mainContainer}>
                <div className={classes.chapContainer}>
                    <div className={classes.chapList}>
                        <div className={classes.singleChap}>
                            <p><span className={classes.chapTitle}>GORIA UBUNTU:</span> Architect and poet Linda Akoth Orando visits her extended
                                family’s housing compound, which was built according to the same principles
                                espoused by precolonial Ugandan society, and talks with her grandfather, an
                                old-time patriarch with a philosophy Orando concludes would make him a role
                                model for our times.</p>
                        </div>
                        <div className={classes.singleChap}>
                            <p><span className={classes.chapTitle}>A TALE OF TWO MATRIARCHS:</span> Wobusobozi Amooti Kangere traces how two
                                powerful women shaped a community in western Uganda – and taught him
                                what it means to be a man.</p>
                        </div>
                        <div className={classes.singleChap}>
                            <p><span className={classes.chapTitle}>A BRIDE'S FAREWELL SONG:</span> Edna Namara travels to the hill country of western
                                Uganda to learn the origins of a mournful song that brides used to sing on
                                their wedding days – and comes back with a lesson in stoicism and the
                                satisfactions of living up to a moral code.</p>
                        </div>
                        <div className={classes.singleChap}>
                            <p><span className={classes.chapTitle}>THE ONCE – AND FUTURE – CLANS OF UGANDA:</span> Journalist and social critic
                                Joachim Buwembo discusses the genius and continuing value of clans as a
                                basis for social cohesion.</p>
                        </div>
                    </div>
                    <div className={classes.bannerContainer}>
                        <img className={classes.groupImg} src={one}>

                        </img>
                    </div>
                </div>
                <div className={classes.reverseChapContainer}>
                    <div className={classes.chapList}>
                        <div className={classes.singleChap}>
                            <p><span className={classes.chapTitle}>MOUNTAIN, OSTRICH and GIRAFFE:</span> A Journey through the Ateker Land of
                                Karamoja: A.K. Kaiza finds in the pastoralist lands of Karamoja a rich
                                heritage, hidden strengths, and modern troubles that reveal much about
                                Uganda’s history.</p>
                        </div>
                        <div className={classes.singleChap}>
                            <p><span className={classes.chapTitle}>AND MONEY MADE MEN GO MAD:</span> Joachim Buwembo deploys wit and a keen,
                                satirical eye to trace the corrosive effect of money on traditional culture.</p>
                        </div>
                        <div className={classes.singleChap}>
                            <p><span className={classes.chapTitle}>IT TAKES A VILLAGE, BUT WHAT IF THE VILLAGE IS DYING?</span> Caroline Ariba
                                examines the ravaging effects of modern economics, changing social values
                                and rapid population growth on the idyllic life she remembers in the village of
                                her childhood days.</p>
                        </div>
                    </div>
                    <div className={classes.reverseBannerContainer}>
                        <img className={classes.groupImg} src={smilingman}>

                        </img>
                    </div>
                </div>
                <div className={classes.chapContainer}>
                    <div className={classes.chapList}>
                        <div className={classes.singleChap}>
                            <p><span className={classes.chapTitle}>OF HEALERS, QUACKS . . .  AND CONFUSION:</span> The breakdown of the old social
                                order and the failure of the modern institutions to fill the void leave people in a
                                farming region caught between traditional and modern beliefs about science,
                                religion and medicine, writes journalist Joseph Elunya, Sr.</p>
                        </div>
                        <div className={classes.singleChap}>
                            <p><span className={classes.chapTitle}>DANCE OF DEATH:</span> Nearly broken under pressures arising from her tribe’s
                                devotion to their ancestors, Regina Asinde describes how she found a
                                spiritual rebirth.</p>
                        </div>
                        <div className={classes.singleChap}>
                            <p><span className={classes.chapTitle}>OUR JUSTICE OR THEIR JUSTICE?</span> The reappearance of a man who left home to
                                serve in the rebel army of notorious warlord Joseph Kony leads his brother,
                                Aliker P’Ocitti, to ponder the stark differences between traditional ideas of
                                justice and harsh modern approaches.</p>
                        </div>
                        <div className={classes.singleChap}>
                            <p><span className={classes.chapTitle}>THE WORDS DIED IN MY MOUTH:</span> Achelam Kinyera tracks the humour,
                                sophistication, creativity and bittersweet realities that come with living in one
                                of the world’s most linguistically diverse societies.</p>
                        </div>
                    </div>
                    <div className={classes.bannerContainer}>
                        <img className={classes.groupImg} src={three}>
                        </img>
                    </div>
                </div>
                <div className={classes.reverseChapContainer}>
                    <div className={classes.chapList}>
                        <div className={classes.singleChap}>
                            <p><span className={classes.chapTitle}>RADIO KATWE, FROM DRUMS TO THE INTERNET:</span> Journalist Joachim Buwembo
                                traces the evolution of communications technologies from being tools for
                                cooperation in tightly-knit communities to instruments of political conflict in a
                                multi-polar world.</p>
                        </div>
                        <div className={classes.singleChap}>
                            <p><span className={classes.chapTitle}>WAKALIWOOD: UGANDA'S ANSWER TO HOLLYWOOD:</span> Journalist and communications
                                student Stephen Ssenkaaba explores the work of noted movie director Isaac
                                Nabwana, whose films use traditional folklore to explore contemporary issues.</p>
                        </div>
                        <div className={classes.singleChap}>
                            <p><span className={classes.chapTitle}>A HEALING TRADITION:</span> Journalist Flavia Nassaka visits with Emilio Ovuga, a
                                psychiatrist and educator, who says today’s doctors could learn some things
                                from his mother, who was a traditional healer.</p>
                        </div>
                        <div className={classes.singleChap}>
                            <p><span className={classes.chapTitle}>A BRIGHT GOLD RAY:</span> Edna Namara shares loving memories of being raised by her
                                grandmother, who showed that there really is life after death.</p>
                        </div>
                    </div>
                    <div className={classes.reverseBannerContainer}>
                        <img className={classes.groupImg} src={four}>

                        </img>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Chapters