import * as React from 'react';
import { Helmet } from 'react-helmet'
import {makeStyles} from '@material-ui/core/styles'
import {useState, useEffect} from 'react'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { Link } from 'react-router-dom'

import
cconteinterview
  from '../pics/cconteinterview.png'

import
    enamarainterivew
    from '../pics/enamarainterview.png'

import
    apocittiinterview
    from '../pics/apocittiinterview.png'

import
    lorandointerview
    from '../pics/lorandointerview.png'

import
    ssenkaabainterview
    from '../pics/ssenkaabainterview.png'

import
    rasindeinterview
    from '../pics/rasindeinterview.png'

import
    caribainterview
    from '../pics/caribainterview.png'

const Afia = ({crossroads, setCrossroads}) => {



    const useStyles = makeStyles((theme) =>({
        body: {
            height: '100%',
            minHeight: '100vh',
            width: '100vw',
            display: 'flex',
            alignContent: 'center',
            flexDirection: 'column',
        },
        titleContainer: {
            [theme.breakpoints.down('md')]:{
                fontSize: '6vw',
                paddingLeft: '2vmin',
                paddingRight: '2vmin',
                paddingBottom: '7vmin',
                WebkitTextStroke: '0px',
                textShadow:'black 0px 0 0px'
                },
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems:'center',
                fontFamily: 'oswald',
                color: '#5a2a20',
                fontSize: '6vmin',
                marginTop: '6vh',
           },
           clickHere: {
            display: 'flex',
            justifyContent:'center',
            paddingBottom: '4vmin',
            fontFamily: 'oswald',
            fontSize: '2.5vmin',
            color: '#5a2a20'
           },
           mainContainer: {
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
           },
           videoContainer: {
            [theme.breakpoints.down('md')]:{
                width: '90%',
                marginBottom: '5vw'
                },
                [theme.breakpoints.down('sm')]:{
                    flexDirection: 'column',
                    paddingTop: '2vh',
                    
                    },
            display: 'flex',
            flexDirection: 'row',
            width: '50%',
            alignItems: 'center',
            marginBottom: '3vw',
            padding: '1vw',

            backgroundColor: '#5a2a20',
            height: '70%',
            // justifyContent: 'center',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)',
            textDecoration: 'none'
           },
           descriptionContainer: {
            [theme.breakpoints.down('md')]:{
                fontSize: '3vmin',
                
                },
                [theme.breakpoints.down('sm')]:{
                    width: '90%',
                    marginTop: '2vh',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0'
                    },
            padding: '2vmin',
            width: '100%',
            fontSize: '1.5em',
            fontSize: '2vmin',
            fontFamily: 'Roboto Serif, serif',
            color:"white",
            display: 'flex',
            justifyContent:'center'
            // marginLeft: '1vw',
           },
           thumbnailContainer: {
            [theme.breakpoints.down('sm')]:{
                width: 'max-content',
                justifyContent: 'center'
                },
            display: 'flex',
            // width: '50%'
           },
           img: {
            [theme.breakpoints.down('sm')]:{
                width: '50vmin'
                },
            width: '30vmin'
           }
    }))

    useEffect(() => {
        setCrossroads(false)
    }, [])

    const classes = useStyles()

    return <>
    <div className={classes.body}>
        <Helmet>
            <title>AFIA PODCAST INTERVIEWS</title>
            <meta
                    name="description"
                    content="Watch a video about wedding traditions in Uganda"
                />
                <meta
                    name="keywords"
                    content="Edna Namara, Uganda, African Weddings, Ugandan Culture"
                />
        </Helmet>
        <div className={classes.title}>
            <div className={classes.titleContainer}>
                AFIA PODCAST INTERVIEWS
            </div>
            <div className={classes.clickHere}>
                Click on a Podcast to Watch the Video
            </div>
        </div>
        <div className={classes.mainContainer}>

                <Link className={classes.videoContainer} to="/afiaconte">
                    <div className={classes.thumbnailContainer}>
                        <img className={classes.img} src={cconteinterview}></img>
                    </div>
                    <div className={classes.descriptionContainer}>
                        Christopher Conte on how a muzungu edits African writers
                    </div>
                </Link>
                
            <Link className={classes.videoContainer} to="/afianamara">
                <div className={classes.thumbnailContainer}>
                    <img className={classes.img} src={enamarainterivew}></img>
                </div>
                <div className={classes.descriptionContainer}>
                    Edna Namara shares her life through rich stories.
                </div>
            </Link>

            <Link className={classes.videoContainer} to="/afiapocitti">
                <div className={classes.thumbnailContainer}>
                    <img className={classes.img} src={apocittiinterview}></img>
                </div>
                <div className={classes.descriptionContainer}>
                    Aliker P’Ocitti on how writing changes the writer and his source.
                </div>
            </Link>

            <Link className={classes.videoContainer} to="/afiaorando">
                <div className={classes.thumbnailContainer}>
                    <img className={classes.img} src={lorandointerview}></img>
                </div>
                <div className={classes.descriptionContainer}>
                    Linda Orando on Uganda’s changing social life.
                </div>
            </Link>

            <Link className={classes.videoContainer} to="/afiassenkaaba">
                <div className={classes.thumbnailContainer}>
                    <img className={classes.img} src={ssenkaabainterview}></img>
                </div>
                <div className={classes.descriptionContainer}>
                    Stephen Ssenkaaba discusses digital media in Africa.
                </div>
            </Link>

            <Link className={classes.videoContainer} to="/afiaasinde">
                <div className={classes.thumbnailContainer}>
                    <img className={classes.img} src={rasindeinterview}></img>
                </div>
                <div className={classes.descriptionContainer}>
                    Regina Asinde Reflects on African spirituality.
                </div>
            </Link>

            <Link className={classes.videoContainer} to="/afiaariba">
                <div className={classes.thumbnailContainer}>
                    <img className={classes.img} src={caribainterview}></img>
                </div>
                <div className={classes.descriptionContainer}>
                    Carol Ariba shares bittersweet memories of Uganda’s troubled villages.
                </div>
            </Link>

        </div>
    </div>
    </>
    
}

export default Afia