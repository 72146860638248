import * as React from 'react';
import { Helmet } from 'react-helmet'
import {makeStyles} from '@material-ui/core/styles'
import {useState, useEffect} from 'react'
import { Link as Link1 } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

import crossroads1 from '../pics/crossroads1.webp'
import crossroads2 from '../pics/crossroads2.webp'
import crossroads3 from '../pics/crossroads3.webp'
import crossroads4 from '../pics/crossroads4.webp'
import crossroads5 from '../pics/crossroads5.webp'
import crossroads6 from '../pics/crossroads6.webp'
import crossroads7 from '../pics/crossroads7.webp'
import crossroads8 from '../pics/crossroads8.webp'
import crossroads9 from '../pics/crossroads9.webp'
import crossroads10 from '../pics/crossroads10.webp'
import crossroads11 from '../pics/crossroads11.webp'
import crossroads12 from '../pics/crossroads12.webp'
import crossroads13 from '../pics/crossroads13.webp'
import crossroads14 from '../pics/crossroads14.webp'
import crossroads15 from '../pics/crossroads15.webp'
import crossroads16 from '../pics/crossroads16.webp'
import crossroads17 from '../pics/crossroads17.webp'

const useStyles = makeStyles((theme) => ({
    body: {
        minHeight: '100vh',
    },
    goBack: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '7vmin',
            marginTop: '5vh',
            },
            maxWidth: '100vw',
            marginTop: '2%',
            marginLeft: '3%',
            display: 'flex',
            alignItems: 'center',
            color: '#5a2a20',
            // fontSize: '3vmin',
            fontFamily: 'oswald',
    },
    goBackText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
            // marginRight: '5vw'
            },
            fontSize: '1.8em'
    },
    
    backIcon: {
        [theme.breakpoints.down('sm')]: {
            transform: 'scale(2)',
            marginRight: '5vw'
            },
        "&:hover": {
            color: 'white',
            transform: 'scale(2.4)',
            cursor: 'pointer'
        },
        marginRight: '3vmin',
        transform: 'scale(2.4)'
    },
    titleContainer: {
        [theme.breakpoints.down('md')]:{
            fontSize: '6vw',
            paddingLeft: '2vmin',
            paddingRight: '2vmin',
            paddingBottom: '7vmin',
            WebkitTextStroke: '0px',
            textShadow:'black 0px 0 0px'
            // marginTop: '5vh'
            },
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems:'center',
            fontFamily: 'oswald',
            color: '#5a2a20',
            fontSize: '6vmin',
            marginTop: '6vh',
            paddingBottom: '4vmin',
            // WebkitTextStroke: '1px',
            // WebkitTextStrokeColor: '#b68f72',
            // textShadow:'black 1px 0 5px'
       },
    cr: {
        width: '35vmin',
        marginLeft: '1vw'
    },
    galleryContainer: {
        [theme.breakpoints.down('sm')]:{
            padding: '3vw'
            },
          padding: '10vmin',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        display: 'flex',
        flexWrap: 'wrap',
      },
    pic: {
        [theme.breakpoints.down('sm')]:{
            height: '40vmin'
            },
          height: '30vmin',
          "&:hover": {
            scale: '1.2',
            cursor: 'pointer'
        }
    },
    pictureViewer: {
        [theme.breakpoints.down('md')]:{
          width: '100vw',
          height: '100%'
          },
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh'
      },
    openedPic: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '5vh',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '2vh',
      width: '90%',
      height: 'auto'
    },
    height: '90%',
    
  },
  close: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10vh',
    },
    "&:hover": {
      cursor: 'pointer'
    },
    color: 'white',
    marginTop: '2vmin'
    
  },
}))

const Crossroadsgallery = ({crossroads, setCrossroads}) => {

    useEffect(() => {
        setCrossroads(true)
    }, [])

    const [pic, setPic] = useState(0)
    const [openModal, setOpenModal] = useState(false)

    const handleOpenPic = (index) => {
        setPic(index)
        setOpenModal(true)
      }
    
    const classes = useStyles()

    const itemData = [
        {
            img: crossroads1
        },
        {
            img: crossroads2
        },
        {
            img: crossroads3
        },
        {
            img: crossroads4
        },
        {
            img: crossroads5
        },
        {
            img: crossroads6
        },
        {
            img: crossroads7
        },
        {
            img: crossroads8
        },
        {
            img: crossroads9
        },
        {
            img: crossroads10
        },
        {
            img: crossroads11
        },
        {
            img: crossroads12
        },
        {
            img: crossroads13
        },
        {
            img: crossroads14
        },
        {
            img: crossroads15
        },
        {
            img: crossroads16
        },
        {
            img: crossroads17
        }
    ]

    return <>
        <div className={classes.body}>
            <div className={classes.goBack}>
                    <Link1 to="/crossroadshome" style={{ color: 'inherit', textDecoration: 'none' }}>
                            <ArrowCircleLeftOutlinedIcon className={classes.backIcon}/>
                    </Link1> <div className={classes.goBackText}>BACK TO CROSSROADS HOME</div>
                </div>
                <Helmet>
                    <title>BOOK LAUNCH PHOTOS</title>
                    <meta
                    name="description"
                    content="View Photos from the Crossroads Book Launch Event"
                />
                </Helmet>
            <div className={classes.titleContainer}>
                        BOOK LAUNCH PHOTOS
            </div>
            {
                openModal ? 

                <div className={classes.pictureViewer}>
                    <img src={itemData[pic].img} className={classes.openedPic}></img>
                    <div className={classes.close} onClick={()=> setOpenModal(false)}><CloseIcon /></div>
                </div>

                :
                <>
                
                <div className={classes.galleryContainer}>
                
                {itemData.map((pic, index) => {
                    return (
                        <div className="picDiv" onClick={() => handleOpenPic(index)}>
                            <img src={pic.img} className={classes.pic}></img>
                        </div>
                    )
                })}
                </div>
                </>
            }
         </div>          
    </>
}


export default Crossroadsgallery