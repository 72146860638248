import * as React from 'react';
import { Helmet } from 'react-helmet'
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Link as Link1 } from 'react-router-dom'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

import harriete from '../pics/harriete.png'
import caroline from '../pics/caroline.png'
import sophie from '../pics/sophie.png'
import elvania from '../pics/elvania.png'
import shifa from '../pics/shifa.png'
import lydia from '../pics/Lydia.png'
import nakisanze from '../pics/nakisanze.png'
import rosey from '../pics/rosey.png'
import peace from '../pics/peace.png'
import hilda from '../pics/hilda.png'
import laura from '../pics/laura.png'
import ronnie from '../pics/ronnie.jpeg'


const useStyles = makeStyles((theme) => ({
    goBack: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '7vmin',
            marginTop: '5vh',
        },
        maxWidth: '100vw',
        marginTop: '2%',
        marginLeft: '3%',
        display: 'flex',
        alignItems: 'center',
        color: '#5a2a20',
        fontFamily: 'oswald',
    },
    goBackText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
        },
        fontSize: '1.8em'
    },

    backIcon: {
        [theme.breakpoints.down('sm')]: {
            transform: 'scale(2)',
            marginRight: '5vw'
        },
        "&:hover": {
            color: 'white',
            transform: 'scale(2.4)',
            cursor: 'pointer'
        },
        marginRight: '3vmin',
        transform: 'scale(2.4)'
    },
    titleContainer: {
        [theme.breakpoints.down('md')]: {
            fontSize: '10vmin',
            paddingBottom: '7vmin',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        },
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'oswald',
        color: '#5a2a20',
        fontSize: '8vmin',
        marginTop: '2vh',
        paddingBottom: '4vmin',
        WebkitTextStroke: '2px',
        WebkitTextStrokeColor: '#b68f72',
        textShadow: 'black 1px 0 5px'
    },
    body: {
        marginTop: '2vmin',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cr: {
        width: '35vmin',
        marginLeft: '1vw'
    },
    authorContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '95vw',
            position: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            width: '95vw',
            position: 'center',
        },
        backgroundColor: '#5a2a20',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)',
        borderRadius: '10px',
        width: '75vw',
        height: '100%',
        padding: '2vh',
        marginBottom: '5vh'
    },
    authorBox: {
        [theme.breakpoints.down('lg')]: {
            marginBottom: '6vh'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
        marginTop: '2vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'sans-serif',
        marginBottom: '3vh',
    },
    reverseAuthorBox: {
        [theme.breakpoints.down('lg')]: {
            marginBottom: '6vh',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
        marginTop: '2vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'sans-serif',
        marginBottom: '3vh',
    },
    nameAndPic: {
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.down('md')]: {
        //    width: '55%'
        },
        [theme.breakpoints.down('lg')]: {
            width: '60%',
         },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    authorPicture: {
        height: 'auto',
        width: '15vw',
        borderRadius: '50%',
        paddingBottom: '1vh',
        [theme.breakpoints.down('sm')]: {
            margin: '2vmin',
            width: '25vh'
        },
        [theme.breakpoints.down('md')]: {
            width: '60%'
        },
    },
    authorName: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            fontSize: '7vmin',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        },
        display: 'flex',
        justifyContent: 'center',
        
        color: '#b68f72',
        fontSize: '3.5vmin',
        fontFamily: 'oswald',
        fontWeight: 'bold',
        WebkitTextStroke: '1px',
        WebkitTextStrokeColor: 'black',
        textShadow: 'black 1px 0 5px'
    },
    sophieName: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            width: '90vw',
            
            fontSize: '5.5vmin',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        }, //NEW
        whiteSpace: 'no-wrap',
        
        color: '#b68f72',
        fontSize: '3vmin',
        fontFamily: 'oswald',
        fontWeight: 'bold',
        WebkitTextStroke: '1px',
        WebkitTextStrokeColor: 'black',
        textShadow: 'black 1px 0 5px'
    },
    authorBio: {
        [theme.breakpoints.down('sm')]: {
            width: '93vw',
            fontSize: '5vmin',
            padding: '5vw'
        },
        width: '70%',
        color: 'white',
        paddingLeft: '5%',
        fontSize: '2vmin',
        
        fontFamily: 'Roboto Serif, serif',

    },
    reverseAuthorBio: {
        [theme.breakpoints.down('sm')]: {
            width: '93vw',
            fontSize: '5vmin',
            padding: '5vw'
        },
        width: '70%',
        color: 'white',
        paddingRight: '5%',
        fontSize: '2vmin',
        fontFamily: 'Roboto Serif, serif',
        

    },
    authorTitle: {
        width: '100%',
        position: 'center',
        alignItems: 'center',
    }
}))

const Crossroadsauthors = ({ crossroads, setCrossroads }) => {

    useEffect(() => {
        setCrossroads(true)
    }, [])

    const classes = useStyles()

    return <>
        <div className={classes.goBack}>
            <Link1 to="/crossroadshome" style={{ color: 'inherit', textDecoration: 'none' }}>
                <ArrowCircleLeftOutlinedIcon className={classes.backIcon} />
            </Link1> <div className={classes.goBackText}>BACK TO CROSSROADS HOME</div>
        </div>
        <div className={classes.titleContainer}>
            AUTHORS
        </div>
        <div className={classes.body}>
            <Helmet>
                <title>AUTHORS</title>
                <meta
                    name="description"
                    content="Meet the authors of Crossroads"
                />
                <meta
                    name="keywords"
                    content="Authors, African Authors, Ugandan Authors"
                />
            </Helmet>
            <div className={classes.authorContainer}>
                <div className={classes.authorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={harriete}></img>
                        <div className={classes.authorName}>HARRIET ANENA</div>
                    </div>
                    <div className={classes.authorBio}>
                        Harriete Anena is a poet and writer from Gulu, Uganda. The author of “a Nation in Labour,” a poetry collection, she has widely published poems and short stories. In 2013, she was shortlisted for the Ghana Poetry Prize She finds pleasure in bullying words for poetic pleasure.
                    </div>
                </div>
                <div className={classes.reverseAuthorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={caroline}></img>
                        <div className={classes.authorName}>CAROLINE ARIBA</div>
                    </div>
                    <div className={classes.reverseAuthorBio}>
                        Caroline Ariba is a features writer with The New Vision, a newspaper based I Kampala, Uganda. Hailing all the way from the eastern district of Kumi in Uganda’s Teso region, she dreams of a career in travel where she can experience and write about different cultures.
                    </div>
                </div>
                <div className={classes.authorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={sophie}></img>
                        <div className={classes.sophieName}>SOPHIE NUWAGIRA BAMWOYERAKI</div>
                    </div>
                    <div className={classes.authorBio}>
                        Sophie was born in southwestern Uganda, but now lives in Kampala, where she works as a teacher of English and literature in an international school. She is a member of Uganda Women Writers’ Association, FEMRITE. She writes short stories and poetry.
                    </div>
                </div>
                <div className={classes.reverseAuthorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={elvania}></img>
                        <div className={classes.authorName}>ELVANIA M. BAZAALA</div>
                    </div>
                    <div className={classes.reverseAuthorBio}>
                        Elvania M. Bazaala is a freelance architect born and raised in the suburbs of Kampala. Her greatest weaknesses are sports and chocolate. She believe that the happenstances of life are the reason this world is colorful.
                    </div>
                </div>
                <div className={classes.authorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={shifa}></img>
                        <div className={classes.authorName}>SHIFA MWESIGYE</div>
                    </div>
                    <div className={classes.authorBio}>
                        Shifa Mwesigye is a journalist, science writer and blogger. She has won a number of awards, including recognition from the Multichoice African Journalism Awards. She is a mother of wonderful twins, and spends her spare time trying to award equal attention to them as they vie for her undivided attention.
                    </div>
                </div>
                <div className={classes.reverseAuthorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={lydia}></img>
                        <div className={classes.authorName}>LYDIA NAMUBIRU</div>
                    </div>
                    <div className={classes.reverseAuthorBio}>
                        Lydia Namubiru is a journalist based in Kampala, Uganda, and mother of  brilliant daughter, Hailey. A graduate of Makerere University, she is a program associate for the African Center for Media Excellence. She has worked as a newspaper reporter and program analyst for international nongovernment organizations.
                    </div>
                </div>
                <div className={classes.authorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={nakisanze}></img>
                        <div className={classes.authorName}>NAKISANZE SEGAWA</div>
                    </div>
                    <div className={classes.authorBio}>
                        Nakisanze Segawa is a performance poet working in Luganda, her native language. She has published poems and short stories in English and Luganda, and recently completed her first novel. Spiritually, she has gone back to her roots, and is a fully-converted adherent to African traditional religion.
                    </div>
                </div>
                <div className={classes.reverseAuthorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={rosey}></img>
                        <div className={classes.authorName}>ROSEY SEMBATYA</div>
                    </div>
                    <div className={classes.reverseAuthorBio}>
                        Rosey Sembatya loves words and dogs. She has a short story, “Nine Lives,” to her name, published in a collection entitled “Summoning the Rains” (2012). Rosey manages an education consultancy that compiles interactive learning texts for students.
                    </div>
                </div>
                <div className={classes.authorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={peace}></img>
                        <div className={classes.authorName}>PEACE TWINE</div>
                    </div>
                    <div className={classes.authorBio}>
                        Peace Twine holds a Master of Arts with post-graduate training in gender and human/women’s rights.  A teacher by profession, she has worked with civil society, and is a director of a secondary school and a member of a number of organizations at the national and regional levels. She is a grandmother.
                    </div>
                </div>
                <div className={classes.reverseAuthorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={hilda}></img>
                        <div className={classes.authorName}>HILDA TWONGYEIRWE</div>
                    </div>
                    <div className={classes.reverseAuthorBio}>
                        Hilda Twongyeirwe is a founding member of Femrite, the Uganda Women Writers Association. She is a member of other art and cultural initiatives, and is a published writer of short stories and children’s books. The mother of one daughter and two sons, she is passionate about women and children’s  issues.
                    </div>
                </div>
                <div className={classes.authorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={laura}></img>
                        <div className={classes.authorName}>LAURA M. WALUSIMBI</div>
                    </div>
                    <div className={classes.authorBio}>
                        Laura M. Walusimbi lives in Kampala, Uganda. She is married and has been blessed with two precious boys. She now works as a freelance journalist and consulting editor. Her greatest desire is to see that her children enjoy their school years.
                    </div>
                </div>
                <div className={classes.reverseAuthorBox}>
                    <div className={classes.nameAndPic}>
                        <img className={classes.authorPicture} src={ronnie}></img>
                        <div className={classes.authorName}>RONNIE OGWANG</div>
                    </div>
                    <div className={classes.reverseAuthorBio}>
                        Ronnie Ogwang, whose painting "Secret Admnirer" adorns the book cover, is a professional visual artist. He holds a Bachelor of Arts in Industrial Fine Arts and Design from Makerere University. He has had exhibitions in Uganda, Kenya, Australia and Germany, and has worked for the US National Library of Medicine and the Central Bank of Uganda. He loves to paint the female figure, and believes women are the most important part of society.
                    </div>
                </div>
            </div>
        </div>
    </>


}

export default Crossroadsauthors