import * as React from 'react';
import { Helmet } from 'react-helmet'
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { ClassNames } from '@emotion/react';
import { Link } from 'react-router-dom'
import { Link as Link1 } from 'react-router-dom'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import rtfcover from '../pics/rtfcover2 copy.png'
import ccover from '../pics/newcrcover.png'
import buypic from '../pics/crossroadsbuypic.jpeg'

const useStyles = makeStyles((theme) => ({
    body: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            position: 'center',
        },
        marginTop:'2vmin',
        width: '100vw',
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    titleContainer: {
        [theme.breakpoints.down('md')]: {
            fontSize: '8vmin',
            paddingBottom: '7vmin',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        },
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'oswald',
        color: '#5a2a20',
        WebkitTextStroke: '2px',
        WebkitTextStrokeColor: '#b68f72',
        fontSize: '8vmin',
        paddingBottom: '4vmin',
        textShadow: 'black 0px 0 5px'
    },
    container: {
        [theme.breakpoints.down('lg')]: {
            fontSize: '2vmin',
            width: '90vmin'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '2vmin',
            width: '75vmin'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vmin',
            paddingBottom: '7vmin',
            width: '95vw',
        },
        width: '50%',
        fontSize: '2vmin',
        fontFamily: 'Roboto Serif, serif',
        color: "white",
        backgroundColor: '#5a2a20',
        padding: '2vh',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)',
        borderRadius: '10px',
        marginRight: '2vw'
    },
    horizontal: {
        [theme.breakpoints.down('sm')]: {
            
            width: '100%',
            position: 'center',
        },
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column-reverse',
        width: '100vw',
        justifyContent: 'center'
    },
    img2: {
        [theme.breakpoints.down('sm')]: {
            width: '35vmin',
            marginRight: '0'
        },
        [theme.breakpoints.down('xs')]: {
            width: '40vmin',
            marginRight: '0'
        },
        width: '25vmin'
    },
    buypic: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            width: '100%',
            marginTop: '4vmin'
        },
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '3vmin'
    },
    span: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vmin',
            color: '#5a2a20',
            fontWeight: 'bold',
        },
        color: '#5a2a20',
        fontWeight: 'bold',
    },
    link: {
        textDecoration: 'none',
        color: 'white',
        borderBottom: '1px solid white',
        borderBottomColor: 'blue'
    
    }
}))

const Bookpromo = ({ crossroads, setCrossroads }) => {

    const classes = useStyles()

    return (
        <div className={classes.body}>
            <Helmet>
                <title>BUY</title>
                <meta
                    name="description"
                    content="Where to Buy Crossroads"
                />
            </Helmet>
            <div className={classes.titleContainer}>
                BACK TO THE BEGINNING...
            </div>
            <div className={classes.horizontal}>
                <div className={classes.container}>
                In 2015, a group of Ugandan women came together to write about how they balance
                traditional beliefs about women’s roles and the demands of living in today’s Uganda. Their
                deeply personal, true stories, published in <Link className={classes.link} to="/crossroadshome"> “Crossroads: Women Coming of Age in Today’s
                Uganda”</Link> struck a chord with readers and eventually led to “Remembering the Future.”
                <br></br>
                <br></br>
                Now for a limited time, Ugandans can get a copy of Crossroads for free when you buy
                Remembering the Future. The authors of both books share a passion for Ugandan culture,
                deep insights based on their personal lives, and uplifting stories about the human spirit. They
                also write beautifully!
                <br></br>
                <br></br>
                You can read about both books on this website. To get this powerful matched set –
                essential reading for anybody who loves Uganda – visit <a target='_blank' className={classes.link}  href="https://ibuapublishing.com">www.ibuapublishing.com</a>
                </div>
                <div className={classes.buypic}>
                    <img className={classes.img2} src={rtfcover}></img>
                    <img className={classes.img2} src={ccover}></img>
                </div>
            </div>
        </div>
    )
}

export default Bookpromo