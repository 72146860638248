import * as React from 'react';
import { Helmet } from 'react-helmet'
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { ClassNames } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';

import { Link as Link1 } from 'react-router-dom'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

import blogpic1 from '../pics/blogpic1.png'
import blogpic2 from '../pics/blogpic2.png'
import conteblogpic from '../pics/conteblogpic.png'


const useStyles = makeStyles((theme) => ({
    goBack: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '7vmin',
            marginTop: '5vh',
        },
        maxWidth: '100vw',
        marginTop: '2%',
        marginLeft: '3%',
        display: 'flex',
        alignItems: 'center',
        color: '#5a2a20',
        fontFamily: 'oswald',
    },
    goBackText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
        },
        fontSize: '1.8em'
    },

    backIcon: {
        [theme.breakpoints.down('sm')]: {
            transform: 'scale(2)',
            marginRight: '5vw'
        },
        "&:hover": {
            color: 'white',
            transform: 'scale(2.4)',
            cursor: 'pointer'
        },
        marginRight: '3vmin',
        transform: 'scale(2.4)'
    },
    titleContainer: {
        [theme.breakpoints.down('md')]: {
            fontSize: '10vmin',
            paddingBottom: '7vmin',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        },
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'oswald',
        color: '#5a2a20',
        fontSize: '8vmin',
        marginTop: '2vh',
        paddingBottom: '4vmin',
        WebkitTextStroke: '2px',
        WebkitTextStrokeColor: '#b68f72',
        textShadow: 'black 1px 0 5px'
    },
    body: {
        marginTop: '2vmin',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',

        alignItems: 'center'
    },
    post: {
        width: '100%',
        borderBottom: '2px solid white',
        marginBottom: '5vh'
    },
    blogHolder: {
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '50vw',
        backgroundColor: '#5a2a20',
        padding: '2vw',
        backgroundColor: '#5a2a20',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)',
        borderRadius: '10px',
        marginBottom: '5vh'
    },
    postTitle: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            fontSize: '5vmin'
        },
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '3vh',
        color: '#30D5CB',
        fontSize: '4vmin',
        fontWeight: 'bold',
        fontFamily: 'Roboto Serif, serif',
    },
    postContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    blogpic1: {
        [theme.breakpoints.down('md')]: {
            
            marginBottom: '10%'
        },
        [theme.breakpoints.down('sm')]: {
            height: '70vw',
            
        },
        height: '20vw',
    },
    textandPic: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin'
            
        },
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: '2vh',
        width: '100%',
        color: 'white',
        fontSize: '2vmin',
        fontFamily: 'Roboto Serif, serif',
        marginBottom: '5vh'
    },
    textHolder: {
        paddingLeft: '1vw',

    },
    picHolder: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vmin'

        },
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: '1.5vmin',
        fontFamily: 'Roboto Serif, serif',

    },
    pic2: {
        [theme.breakpoints.down('sm')]: {
            width: '70vw',
            marginBottom: '10%'

        },
        width: '50%',
        paddingBottom: '2vh',
    },
    picAtt: {
        marginBottom: '5vh',
        width: '80%',
        display: 'flex',
        justifyContent: 'center'
    },
    post2: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            fontSize: '5vmin'
        },
        width: '100%',
        borderBottom: '2px solid white',
        paddingTop: '2vh'
    },
    postAtt: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '3vmin'
        },
        fontSize: '2vmin',
        color: 'white'
    },
    intro: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            fontSize: '5vmin'
        },
        paddingTop: '2vh',
        width: '100%',
        color: 'white',
        fontSize: '2vmin',
        fontFamily: 'Roboto Serif, serif',
    },
    interview: {
        display: 'flex',
        flexDirection: 'column'
    },
    question: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin'
        },
        marginTop: '5vh',
        marginBottom: '5vh',
        color: '#b68f72',
        fontSize: '2vmin',
        fontFamily: 'Roboto Serif, serif',
        fontStyle: 'italic'
    },
    answer: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin'
        },
        color: 'white',
        fontSize: '2vmin',
        fontFamily: 'Roboto Serif, serif',
    },
    finalAnswer: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin'
        },
        color: 'white',
        fontSize: '2vmin',
        fontFamily: 'Roboto Serif, serif',
        marginBottom: '3vh'
    },
    post3: {
        width: '100%',
        borderBottom: '2px solid white',
        marginTop: '5vh',
    },
    postHolder: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column-reverse',
            justifyContent: 'center',
            alignItems: 'center',
            width: '90%'
        },
        fontSize: '2vmin',
        marginTop: '2vh',
        width: '100%'
    },
    thirdPost: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin'
        },
        color: 'white',
        fontSize: '2vmin',
        fontFamily: 'Roboto Serif, serif',
        paddingBottom: '2vh'
    },
    contePic: {
        [theme.breakpoints.down('sm')]: {
            width: '75%',
            marginBottom: '2vh',
            marginTop: '2vh'
        },
        float: 'right',

        width: '15vw',
        marginLeft: '2vw',
        marginBottom: '1vh'
    }
}))

const Crossroadsblog = ({ crossroads, setCrossroads }) => {

    useEffect(() => {
        setCrossroads(true)
    }, [])

    const classes = useStyles()

    return (<>
        <div className={classes.goBack}>
            <Link1 to="/crossroadshome" style={{ color: 'inherit', textDecoration: 'none' }}>
                <ArrowCircleLeftOutlinedIcon className={classes.backIcon} />
            </Link1> <div className={classes.goBackText}>BACK TO CROSSROADS HOME</div>
        </div>
        <div className={classes.titleContainer}>
            BLOG
        </div>
        <div className={classes.body}>
            <Helmet>
                <title>BLOG</title>
                <meta
                    name="description"
                    content="Read Blog Articles Related to Crossroads"
                />
                <meta
                    name="keywords"
                    content="Authors, African Authors, Ugandan Authors"
                />
            </Helmet>
            <div className={classes.blogHolder}>
                <div className={classes.post}>
                    <div className={classes.postTitle}>
                        Nakisanze Segawa Champions a Literary Giant
                    </div>
                    <div className={classes.postContent}>
                        <div className={classes.textandPic}>
                            <img className={classes.blogpic1} src={blogpic1}></img>
                            <div className={classes.textHolder}>
                                In the opening essay of Crossroads, writer Nakisanze Segawa recalls a turning point in her life. “I read The River Between, a classic by the Kenyan author Ngugi wa Thiong’0, who was baptized James Ngugi, but later dropped his Christian name,” she recalls. “I fell in love.”
                                <br></br>
                                <br></br>
                                Now, she has acted on that love by translating Ngugi’s fable, “The Upright Revolution — Or Why Humans Walk Upright,” into her native tongue, Luganda. Her translation is one of more than 30 in a single volume that is said to make Ngugi the most translated African writer. Nakisanze’s mastery of Luganda is well known to those who have seen her perform her own poetry in her native tongue — and, indeed, to those who saw her read excerpts from her Crossroads story in Luganda during the book’s formal launch in Kampala. But she is modest about her latest achievement, saying simply that it was a “privilege” to be part of the project.
                            </div>
                        </div>
                        <div className={classes.picHolder}>
                            <img className={classes.pic2} src={blogpic2}></img>
                            <div className={classes.picAtt}>Ngugi wa Thiong’o pictured at the Edinburgh book festival in 2006. Photograph: Murdo MacLeod for the Guardian.</div>
                        </div>
                    </div>

                </div>
                <div className={classes.post2}>
                    <div className={classes.postTitle}>
                        Ask the Author: Lydia Namubiru
                    </div>
                    <div className={classes.postAtt}>
                        Reprinted from “The Bandwagon,” February 26, 2016
                    </div>
                    <div className={classes.postContent}>
                        <div className={classes.intro}>
                            Following on from Ryan’s glowing review of Crossroads: Women Coming of Age in Today’s Uganda, I spoke to Lydia Namubiru, just one of the talented writers involved in the book.
                            <br></br>
                            <br></br>
                            Meet Lydia Namubiru, a Ugandan journalist and writer. She lived in Uganda all her life, before moving to New York 7 months ago to gain her masters degree in journalism at Columbia University.She started working as a journalist/writer for Uganda’s biggest media house, The New Vision, and later went into the development/aid sector, where she worked for Grameen Foundation and Marie Stopes Uganda as an evaluation specialist. She then returned to journalism, to start a data journalism training program at the African Centre for Media Excellence in Kampala. She has a 9 year old daughter, Hailey, who she calls her shot of moonlight.
                            <br></br>
                            <br></br>
                            I wanted to know more about Lydia’s writing, and what inspired her to begin. Lydia wrote two essays for the book: Of Gods and Ghosts and It’s Complicated. Both explore Lydia’s conflicted feelings: In the first, the conflicted feelings are about African religious practice, which she is drawn to, but scared of at the same time, due to the systematic way in which the more powerful imported religions, Christianity in particular, perpetuated a rhetoric that demonises it. In the second, the conflicted feelings are about the Western world. Like many Ugandans, Lydia admires a lot of things about it: its cultural freedoms, its economic productivity, the gravitas it carries in intellectual spaces, and so on. But she is also angry about its historical and current relationship with my own part of the world. Historically, she is angry about colonialism and its related enterprises. Currently, she is angry about the paternalistic and often racist conduct of its development organisations, and the expatriates who work for them.
                        </div>
                        <div className={classes.interview}>
                            <div className={classes.question}>
                                What inspired you to start writing?
                            </div>
                            <div className={classes.answer}>
                                "Both of my parents are writers, so home was the proverbial ‘forest of books’ that legendary Ugandan poet Okot P’Bitek once described. There were books everywhere in my parents’ house, even under the beds. I became a compulsive reader. By the time  I was 12, I had read Song of Lawino, (from which I am picking the above description), a text that my mother was reading for her literature classes at university. By the time I was 20, I felt I had read so many books that I turned to my roommate and said, “I think I can write a book myself.” So I started writing a novel, which I quickly abandoned. The same fate has befallen all my other attempts at novels. But, after university, where I studied library and information science, I was desperate for work, and so I agreed to write a society column for about my college friends and the drunken misadventures we had had. That’s how I started writing. I wasn’t inspired to write, per se. I just started doing it because David Mukholi, the editor of Sunday Vision at the time, thought I could do it, and I needed the money. I am really grateful though, that after meeting me just once, he saw her far more into my talents than I did at the time."
                            </div>
                            <div className={classes.question}>
                                Tell me more about your journalism background.
                            </div>
                            <div className={classes.answer}>
                                "About six months after I started writing the column, Ernest Bazanye, the society editor then (now dear friend), advised me to apply for a full time job with the paper. “You will get health insurance,” was his wise advice. So I did, and from that point on became a journalist, officially. Chris Conte, (now editor of Crossroads) got me really interested in writing about population issues, because Uganda was and still is in the midst of a population boom. Covering population issues naturally led into reproductive health issues and therefore women’s reproductive and other rights. A lot of my journalism has revolved around that cycle."
                            </div>
                            <div className={classes.question}>
                                Would you consider yourself a feminist? If so, do your feminist views differ from those of Western feminists?
                            </div>
                            <div className={classes.answer}>
                                "My professional work made a feminist out of me. In many ways, I am not a typical Ugandan woman. I have been very privileged, as you probably can tell from my career trajectory. Initially, my feminism was more personal than political – in a society that still likes to remind women that a good woman is a dignified one, I broke out writing about my wildly liberal private life, for the column and on a blog.  But working on population issues opened my eyes to far wider injustices that society forces upon women; negligence of maternal health services, silence about domestic violence, state overreach into women’s bodily autonomy, refusal to enact equality laws around marriage and divorce… The list is long. Nearly endless, sadly. So yes, I am a feminist – both personally and politically, if the two can be separated.
                                Ideologically, I don’t think my feminism is different from that of Western feminists’, but coming from different cultures makes our feminist frontiers different. For example, coming from a place where women still routinely die in childbirth means that Lena Dunham’s self-affirmative war on body shaming is largely too futuristic for me to be emotionally invested in."
                            </div>
                            <div className={classes.question}>
                                What are you currently reading? Do you have any book recommendations?
                            </div>
                            <div className={classes.answer}>
                                "I am reading a book on race and the incorporation of white, Arab and black African immigrants into US society. It is very academic, so I’m going to recommend a different one. Boutros Boutros-Ghali, who was UN secretary-general when it failed to respond to the Rwandan genocide, just died. Read Linda Melvern’s book: A People Betrayed. In there, the recently deceased diplomat has a cameo role brokering a $26m arms deal for Egypt with the Rwandan government that was soon after executing the genocide. Umm, I am not one to let people rest in peace."
                            </div>
                            <div className={classes.question}>
                                What does being a woman mean to you?
                            </div>
                            <div className={classes.answer}>
                                "To be born a woman is to born into the thick of things – political stands, social prescriptions, family intricacies, pretty intense biology. So to me, living as a woman is an everyday exercise in pulling bits of me out of the thick of things. Hopefully, by the time I die, I will be satisfied that enough of me was returned to me, away from the thick of things. And, hopefully, by the time my daughter is my age, being a woman will be light enough that she can build her own thick of things, without giving gender much thought."
                            </div>
                            <div className={classes.question}>
                                What’s next? Are you working on anything else?
                            </div>
                            <div className={classes.finalAnswer}>
                                "I am working on getting my master’s degree – nothing terribly exciting to anyone but me, and perhaps my mother."
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.post3}>
                    <div className={classes.postTitle}>
                        Is a Mzungu Man Qualified to Edit Memoirs of Ugandan Women?
                    </div>
                    <div className={classes.postHolder}>
                        <img className={classes.contePic} src={conteblogpic}></img>
                        <div className={classes.thirdPost}>
                            With the days counting down to the official launch of “Crossroads,” I had a thought-provoking conversation with Rebecca Rwakubukoza, a Ugandan journalist who writes a blog called “Mon pi Mon: Women for Women Uganda.” Although she hadn’t yet had a chance to read the book, she began by saying she was skeptical that somebody of my nationality (American) and gender (male) could produce an authentic book by Ugandan women.
                            <br></br>
                            <br></br>
                            I understand her concern. I have wrestled with this issue for quite some time. Mindful of the role the West has played in Africa’s history and its outsized influence today, I am eager to avoid doing anything that would be interpreted as heavy-handed. There has been more than enough of that. Yet like many westerners, I have a deep attachment to Africa, and yearn to be involved with its people. Since I am a life-long reporter and editor, journalism was the inevitable form that my involvement would take.
                            <br></br>
                            <br></br>
                            In talking with Rebecca, my first line of defense was to stress my commitment to professionalism. I strongly believe that a story – especially an autobiography – belongs to the writer. My role as editor is to help writers communicate clearly and persuasively. But I claim no right to tell them what to say or to twist the meanings they intend to convey to fit my own beliefs. The writers of “Crossroads” decided what they would write, and they had the power to reject my suggestions and withhold their stories from publication. Also, they hold the copyrights on their stories.
                            <br></br>
                            <br></br>
                            The other side of this equation is that writers must resist when an editor pushes them in what they consider a wrong direction. Reporters and editors must discipline each other. Rebecca suggests, however, that my argument overlooks the disproportionate power I held as a white man working with women in a traditionally patriarchal society. I wonder. I certainly didn’t find the “Crossroads” writers to be passive (in fact, I have a few bruises that prove otherwise). But in a society where civility is highly valued and aggressive argument often is considered impolite, I can imagine Ugandan women holding their tongues rather than confronting me.
                            <br></br>
                            <br></br>
                            Rebecca says the power imbalance could be reduced and her concerns eased if “Crossroads” had a Ugandan co-editor. Very interesting idea. I am sure I would learn much from such an arrangement. Would I have been willing to see my own role diluted? It probably depends on whether the co-editor and I could establish a good working relationship. By the same token, Ugandans too might reserve judgment until they knew who the new editor would be; in my experience, they don’t automatically side with their fellow countrymen. So there’s no guarantee that a two-editor system will work.
                            <br></br>
                            <br></br>
                            As Rebecca and I continued to talk, it became clear to both of us that we are separated in part by the audiences we each seek to address. My goal in “Crossroads” was to reach international readers, including people in my country, Uganda and everywhere between. So as an editor I objected when my writers used expressions or phrases that I thought non-Ugandans would find hard to understand. I also asked the writers to give English translations for non-English phrases, and I put the untranslated phrases in italics to reduce readers’ confusion. Some Ugandans find those conventions demeaning, but the goal was purely practical — I wanted to reach a broad audience, not to insult anyone.
                            <br></br>
                            <br></br>
                            Rebecca, on the other hand, is mainly interested in writing for fellow Ugandans, so she doesn’t share my concerns. I am certain, judging from conversations I have had with Ugandan friends, that there is a richness to the kind of all-Ugandan writing she advocates that I can barely appreciate. More power to people like her — including Nakisanze Segawa, one of the Crossroads writers, who performs her poetry exclusively in Luganda, her native tongue.
                            <br></br>
                            <br></br>
                            In ways, Rebecca and I pursue the same goals, but from very different starting points. Rebecca starts in Uganda, and wants to preserve its unique heritage. I start outside, and want to serve Ugandans by helping them tell their story to an international audience that increasingly influences many aspects of their lives.
                            <br></br>
                            <br></br>
                            Now readers can make their own judgments. In trying to ensure that “Crossroads” stories would resonate with readers from my own culture, did I make them less palatable to Ugandans,  inadvertently erase culturally important nuances or unintentionally shape “Crossroads” to fit American preconceptions of Uganda rather than Ugandan perceptions of themselves? If so, it’s hard to escape the conclusion that I am part of the global homogenization machine. I hope not. And if the standardized English of Crossroads is less appealing to a Ugandan readers, I hope the offsetting benefit of communicating on an international stage and demonstrating the many strengths of Ugandan culture makes the sacrifice worthwhile.
                            <br></br>
                            <br></br>
                            Thanks to Rebecca for seeking me out and raising important issues. The book will reach the Ugandan market at 4 pm on Saturday, 20 June, in a launching ceremony at the National Theater in Kampala. The launch will be part of the 2015 Writivism Festival organized by the Center for African Cultural Excellence. If you are within reach, please come, and wherever you are, please send feedback.
                            <br></br>
                            <br></br>
                            –Christopher Conte
                            <br></br>
                            <br></br>
                            *Mzungu is a Bantu language term for people of European descent
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )

}

export default Crossroadsblog;