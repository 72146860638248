import * as React from 'react';
import { Helmet } from 'react-helmet'
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles'

import { Link as Link1 } from 'react-router-dom'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

import chapterspic from '../pics/crossroadschapterspic.png'
import chapterspic2 from '../pics/chapterspic2.png'

const useStyles = makeStyles((theme) => ({
    body: {
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    goBack: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '7vmin',
            marginTop: '5vh',
        },
        maxWidth: '100vw',
        marginTop: '2%',
        marginLeft: '3%',
        display: 'flex',
        alignItems: 'center',
        color: '#5a2a20',
        fontFamily: 'oswald',
    },
    goBackText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vmin',
        },
        fontSize: '1.8em'
    },

    backIcon: {
        [theme.breakpoints.down('sm')]: {
            transform: 'scale(2)',
            marginRight: '5vw'
        },
        "&:hover": {
            color: 'white',
            transform: 'scale(2.4)',
            cursor: 'pointer'
        },
        marginRight: '3vmin',
        transform: 'scale(2.4)'
    },
    titleContainer: {
        [theme.breakpoints.down('md')]: {
            fontSize: '10vmin',
            paddingBottom: '7vmin',
            textShadow: 'black 0px 0 0px',
            WebkitTextStroke: '0px',
        },
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'oswald',
        color: '#5a2a20',
        fontSize: '8vmin',
        marginTop: '2vh',
        paddingBottom: '4vmin',
        WebkitTextStroke: '2px',
        WebkitTextStrokeColor: '#b68f72',
        textShadow: 'black 1px 0 5px'
    },
    cr: {
        width: '35vmin',
        marginLeft: '1vw'
    },
    picContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    pic: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 'width 100%',
            width: '90%'
        },
        width: '25vw'
    },
    mainContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '90vw'
        },
        width: '75vw',
        backgroundColor: '#5a2a20',
        padding: '2vmin',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.6)',
        borderRadius: '10px',
        marginBottom: '5vh'
    },
    chaptersIntro: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '4.5vmin'
        },
        paddingTop: '2vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
        fontSize: '2vmin',
        fontFamily: 'Roboto Serif, serif',
    },
    introText: {
        [theme.breakpoints.down('sm')]: {
            width: '90vw'
        },

        width: '90%'
    },
    listHolder: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '2vh'
    },
    listWrapper: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column-reverse',
            justifyContent: 'center',
            alignItems: 'center',
            width: '90%'
        },
        paddingTop: '2vh',
        width: '90%'
    },
    list: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '4.5vmin'
        },
        color: 'white',
        fontSize: '2vmin',
        fontFamily: 'Roboto Serif, serif',
    },
    highlight: {
        paddingBottom: '3vh',
    },
    pic2: {
        [theme.breakpoints.down('sm')]: {
            width: '75%'
        },
        float: 'right',

        width: '20vw',
        marginLeft: '5vw'
    }
}))

const Crossroadschapters = ({ crossroads, setCrossroads }) => {

    useEffect(() => {
        setCrossroads(true)
    }, [])

    const classes = useStyles()


    return (
        <>
            <div className={classes.goBack}>
                <Link1 to="/crossroadshome" style={{ color: 'inherit', textDecoration: 'none' }}>
                    <ArrowCircleLeftOutlinedIcon className={classes.backIcon} />
                </Link1> <div className={classes.goBackText}>BACK TO CROSSROADS HOME</div>
            </div>
            <div className={classes.body}>
                <Helmet>
                    <title>CHAPTERS</title>
                    <meta
                    name="description"
                    content="Take a Peek at the Chapters in Crossroads"
                />
                <meta
                    name="keywords"
                    content="Ugandan Culture, African Culture, Ugandan Stories"
                />
                </Helmet>
                <div className={classes.titleContainer}>
                    CHAPTERS
                </div>
                <div className={classes.mainContainer}>
                    <div className={classes.picContainer}>
                        <img className={classes.pic} src={chapterspic}></img>
                    </div>
                    <div className={classes.chaptersIntro}>
                        <div className={classes.introText}>
                            Women are guardians of cultural traditions, yet they also stand at the cutting edge of change. In “Crossroads,” a group of Ugandan women explore the complicated role they play in a society undergoing rapid change, from their first childhood encounters with society’s expectations to the trials and tribulations of early adulthood, to their emergence as increasingly empowered leaders in the workplace and politics.

                            Highlights include:
                        </div>
                    </div>
                    <div className={classes.listHolder}>
                        <div className={classes.listWrapper}>
                            <img className={classes.pic2} src={chapterspic2}></img>
                            <ul className={classes.list}>
                                <li className={classes.highlight}>An avowed nonbeliever examines the many influences in her spiritual life – including Islam and diverse forms of Christianity – and finds traditional African beliefs hold the greatest sway over her.</li>
                                <li className={classes.highlight}>A former graduate student tells being held without charge and tortured in a secret prison shaped her ideas about government. </li>
                                <li className={classes.highlight}>A young professional describes how Ugandan girls traditionally were trained for womanhood, and how she balances lessons from the past with her current status as a modern, liberated woman.</li>
                                <li className={classes.highlight}>A journalist describes the lingering effects of growing up in a war zone, while another tells the harrowing story of being sexually abused as a child.</li>
                                <li className={classes.highlight}>One author describes desperately visiting both traditional healers and western medical practitioners for a cure to a childhood affliction, ultimately to find that healing came through the love of her father.</li>
                                <li className={classes.highlight}>Two women separately describe their struggles to understand their sexual identity, and how they reached divergent conclusion</li>
                                <li className={classes.highlight}>A former nonprofit-organization staffer reflects on her love of western literature and musicals, but mocks the culture of western aid organizations.</li>
                                <li className={classes.highlight}>A budding performance artist explains the traditional meaning of Ugandan names, and explains why she gave herself a new and unconventional name in a quest to define herself.</li>
                            </ul>
                        </div>
                    </div>
                    <div className={classes.chaptersIntro}>
                        <div className={classes.introText} style={{ paddingBottom: '2vh' }}>
                            Editor Christopher Conte spent three years in Uganda as a Knight International Journalism Fellow, and has worked as a consultant for the World Bank and other organizations in the US and abroad. The idea of producing a collection of factual, first-person essays was born out of discussions with several Ugandan women journalists.
                            <br></br>
                            <br></br>
                            “I was struck by the sophistication of these women, and by the grace with which they combine reverence for their cultural traditions with profound self-awareness and a quiet determination to chart their own paths,” Conte says. “Their stories, while uniquely Ugandan, tell us much about what we condescendingly call the ‘developing’ world, where women are at once the guardians of tradition and the vanguard of social change.”
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Crossroadschapters